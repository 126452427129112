export default function() {

    let serviceUrl = "http://localhost:0808/baccredomatic";

    const stringify = function (json) {
        // http://www.west-wind.com/weblog/posts/2009/Sep/15/Making-jQuery-calls-to-WCFASMX-with-a-ServiceProxy-Client
        var reIso = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
        /// <summary>
        /// Wcf specific stringify that encodes dates in the
        /// a WCF compatible format ("/Date(9991231231)/")
        /// Note: this format works ONLY with WCF.
        ///       ASMX can use ISO dates as of .NET 3.5 SP1
        /// </summary>
        /// <param name="key" type="var">property name</param>
        /// <param name="value" type="var">value of the property</param>
        return JSON.stringify(json, function(key, value) {
            if (typeof value == "string") {
                var a = reIso.exec(value);
                if (a) {
                    var val = '/Date(' + new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6])).getTime() + ')/';
                    this[key] = val;
                    return val;
                }
            }
            return value;
        });
    };

    this.invoke = function (data, method, onSuccess, onError, onlyDemo) {

        if (!onlyDemo) onlyDemo = false;

        if (onlyDemo) {
            onSuccess(JSON.parse('{"acqNumber":"","hostDate":"01292025","hostTime":"233927","responseCode":"CE","responseCodeDescription":"ERROR DE COMUNICACION AL DESCARGAR CONFIGURACION","printVoucher":"0","trnTotalTime":"724"}'))
            return false;
        }

        const config = {
            method: 'POST',
            mode: 'cors',
            body: stringify(data),
            headers: new Headers({
                'Accept': '*/*',
                'Content-Type': 'application/json',
            }),
        }

        fetch(serviceUrl + "/" + method, config)
            .then(response => response.json())
            .then(result => {
                let isVoid = result === "";

                // remover error de descarga
                result = result.replace('\u000d\u000aDownload Status error\u000d\u000a', '');

                let res;
                if (!isVoid) {
                    res = JSON.parse(result);
                }
                else {
                    res = {};
                }
                if (onSuccess) {
                    onSuccess(res);
                }
            })
            .catch((e) => {
                console.log('BAC-PINPAD-Invoke ERROR:'+ e);
                if (onError) {
                    onError();
                }
            });
    }
}
