<template>
  <setting-layout :active="35">
      <div class="card">
          <div class="card-header">
              <h4 class="card-title">Bodegas por producto</h4>

              <div> 
                  <button class="btn btn-primary btn-sm d-none d-sm-inline mr-2" @click="showMasiveModal = true">
                      Asignacion masiva
                  </button>
                  <button :class="!asignar ? 'btn btn-secondary btn-sm ml-2' : 'btn btn-warning btn-sm ml-2'" @click="toggleAsignar">
                      {{ asignar ? 'Deshabilitar asignacion' : 'Habilitar asignacion' }}
                  </button>
              </div>
          </div>
          <div class="card-body">
              <div v-if="asignar" class="card-subtitle text-primary font-weight-semibold">
                  Seleccione los productos a los que les asignara una locacion
              </div>
              <div v-else class="card-subtitle text-primary font-weight-semibold">
                 Asignacion de bodegas por producto
              </div>
              <p class="mt-2">Seleccionar producto por nombre o locacion:</p>
                <div class="d-flex align-items-center">
                <!-- New selector and search button -->
                <input type="text" placeholder="Ingrese nombre del producto..." v-model="filter" class="form-control mr-2" @keyup.enter="searchProducts">
                <select id="warehouseSelector" v-model="selectedWarehouse" class="form-control mr-2" @change="searchProducts">
                  <option value="none">Productos sin bodega</option>
                  <option v-for="location in locations" :key="location.id" :value="location.id">
                    {{ location.nombre }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="searchProducts">Buscar</button>
              </div>
              <p  v-if="selectedProducts.length" class="mt-3">Seleccionar locacion final:</p>
              <div v-if="selectedProducts.length" class="d-flex align-items-center">
                <select id="finalLocationSelector" v-model="finalLocation" class="form-control mr-2">
                  <option value="">Seleccionar locacion</option>
                  <option v-for="location in locations" :key="location.id" :value="location.id">
                    {{ location.nombre }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="asignarLocacion">Asignar</button>
              </div>
              <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <nav aria-label="navigation" class="p-2">
                        <ul  v-if="productList.length" class="pagination">
                            <li class="page-item" v-for="item in pagination" :key="'page' + item">
                                <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; searchProducts();" :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                <div v-else class="page-link">{{ item }}</div>
                            </li>
                        </ul>
                    </nav>
                </div>
              </div>
              <div class="form" style="max-height: 600px; overflow-y: auto;">
                  <ul v-if="productList.length">
                      <li v-for="item in productList" :key="item.id" class="h-50" @click="toggleSelection(item.id)" :class="{'shake': asignar}">
                          <div class="listItem">
                              <div class="row">
                                  <div class="col-6 col-md-2">
                                      <div>
                                          <img v-if="item.thumbnail !== ''" :src="item.thumbnail" class="img-thumbnail">
                                          <i v-else class="fa fa-image fa-3x"></i>
                                      </div>
                                  </div>
                                  <div class="col-6 col-md-6">
                                      <div>
                                          <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                          <div class="text-primary">SKU: {{ item.sku }}</div>
                                          <div class="text-primary">Locacion: {{ item.locacion ? item.locacion.nombre : 'Sin locacion asignada' }}</div>
                                          <div>Precio: {{ item.precio }}</div>
                                      </div>
                                  </div>
                                  <div class="col-12 col-md-4 text-right">
                                      <div v-if="asignar" class="edit-option">
                                          <input type="checkbox" :value="item.id" v-model="selectedProducts" @click.stop>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </li>
                  </ul>
                  <div v-else class="text-center mt-3">
                      No se encontraron productos.
                  </div>
              </div>
          </div>
      </div>
      <div v-if="showMasiveModal" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal-cita-titulo">Asignacion masiva</h5>
                    </div>
                    <div class="modal-body">
                        <div class="mt-2">
                            Esta ventana te permite asignar locacion a productos de forma masiva, puedes utilizar un archivo .xlsx o .csv
                        </div>
                        <div class="mt-4 text-dark text-center">
                            <i class="fa fa-arrow-circle-down"></i> Sube el archivo que contenga en el primer campo el SKU del producto y en el segundo el Código de bodega 
                        </div>
                        <div class="mt-1 text-dark text-center">
                            <span class="text-primary">(codigo de producto (sku) | codigo de bodega).</span>
                        </div>
                        <div class="mt-4">
                            <FileUploader url="products/massive/assignment" :accepted-mime="acceptedMime" remove-file-on-finish="0" @fileSuccess="fileSuccess" @errorUpload="errorUpload" v-model:file-uploaded="fileUploaded"></FileUploader>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="btn btn-secondary" @click="showMasiveModal = false" data-dismiss="modal">Cerrar</div>
                    </div>

                </div>
            </div>
      </div>
  </setting-layout>
</template>

<style scoped>
.shake {
    animation: shake 0.4s;
}

</style>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import FileUploader from 'src/components/files/FileUploader';

import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
  components: {
      settingLayout,
      FileUploader
  },
  data() {
      return {
          showMasiveModal: false,
          showMultipleModal: false,
          selectedWarehouse: 'none',
          locations: [],
          productList: [],
          pagination: [],
          pageSelected: 1,
          limit: 10,
          filter: '',
          searchTerm: '',
          selectedProducts: [],
          asignar:false,
          finalLocation: '',

        //   massive
        acceptedMime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv',
        fileUploaded: [],
      };
  },
  mounted() {
      this.loadLocations();
      this.searchProducts();
  },
  watch: {
      filter(val) {
          const self = this;
          if(val.length >= 1){
              self.pageSelected = 1;
              self.limit = 20;
          }
          else{
              self.filter = '';
              self.limit = 10;
          }
      }
  },
  methods: {
       fileSuccess(data) {
        console.log( data );
        // if (typeof data.type !== 'undefined') {
        //     if (data.type === 'preview') {
        //         this.preview = data;
        //     }
        //     else {
        //         this.process = data;
        //         this.preview = {};
        //     }
        // }
      },
      errorUpload(response) {

        if (Object.prototype.toString.call(response.data) === '[object Array]') {
            const errorsList = response.data.join(', ');
            API.showErrorNotify('Por favor revise campos', errorsList);
        }
      },
      loadItems() {
          const self = this;
          API.send('GET', '/products/allCategoria/1', {},
              function (data) {
                  if (data.status) {
                      self.categories = data.data;
                  }
              },
              function (data) {
                  API.showErrorAlert(data.msg);
              })
      },
      loadLocations() {
          const self = this;
          API.send('GET', '/locaciones/all', {},
              function (data) {
                  if (data.status) {
                      self.locations = data.data;
                  }
              },
              function (data) {
                  API.showErrorAlert(data.msg);
              })
      },
      searchProducts() {
          const self = this;
          let locacionId = self.selectedWarehouse === 'none' ? '' : self.selectedWarehouse;
          self.searchTerm = self.filter.length === 0 ? '' : self.filter;
          API.send('GET', `products/by-location/1/${self.pageSelected}/${self.limit}/${locacionId}/${self.searchTerm}`, {},
              function (data) {
                  if (data.status) {
                      self.productList = data.data.productos;
                      if (self.productList.length < 9) {
                            self.pagination = [];
                        } else {
                            self.pagination = data.data.paginas;
                        }
                  } else {
                      self.productList = [];
                      self.pagination = [];
                  }
              },
              function (data) {
                  API.showErrorAlert(data.msg);
              })
      },
      toggleSelection(productId) {
        if (this.asignar) {  
            const index = this.selectedProducts.indexOf(productId);
            if (index > -1) {
                this.selectedProducts.splice(index, 1);
            } else {
                this.selectedProducts.push(productId);
            }
        }
      },
      toggleAsignar() {
          this.asignar = !this.asignar;
      },
      clearAsignacionData() {
          this.selectedProducts = [];
          this.finalLocation = '';
          this.asignar = false;
      },
      asignarLocacion() {
        const self = this;
          if (this.selectedProducts.length && this.finalLocation) {
              const payload = {
                  productIds: this.selectedProducts,
                  locacionId: this.finalLocation
              };
              API.send('POST', 'products/assign-location', payload,
                  function (data) {
                      if (data.status) {
                          API.showSuccessNotify(data.msg);
                          self.searchProducts();
                          self.clearAsignacionData();
                      } else {
                          API.showErrorAlert(data.msg);
                      }
                  },
                  function (data) {
                      API.showErrorAlert(data.msg);
                  });
          } else {
              API.showErrorAlert('Debe seleccionar productos y una locacion final.');
          }
      }
  },
};
</script>
