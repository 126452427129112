<template>
    <template v-if="GetReloadFicha">
        <div class="my-5 p-4">
                <!-- Empty -->
        </div>
    </template>
    <template v-else>
        <box title="Listado de Historial pre existente" icon="fa fa-paperclip" :contraction='true'>
            <div v-if="typeof GetMedicoFichaActive.metadata.attach === 'undefined'">
                <div class="text-center text-danger">No tiene adjuntos cargados</div>
            </div>
            <div v-else>
                <div class="modo-vista mt-2 p-2 d-flex justify-content-end align-items-center">
                    <div class="icon-container p-2">
                        <i class="fa-solid fa-table-list p-0 m-0" @click="viewMode = 'table'"></i>
                    </div>
                    <div class="icon-container ml-2 p-2">
                        <i class="fa-solid fa-grip p-0 m-0" @click="viewMode = 'grid'"></i>
                    </div>
                </div>
                <section v-if="GetHistoryFiltred && viewMode === 'grid'" class="row mt-1 p-2">
                    <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                        <div class="col-12 mt-2" v-if="Object.keys(item.metadata).length > 0">
                            <h6 class="font-weight-bold w-100">Historial de Orden N° {{ item.ordenId }} con fecha {{ dayjs(item.fechaReserva).format('DD-MM-YYYY') }}</h6>
                            <hr class="my-1 w-100">
                            <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                                <div class="row mt-2" v-if="Object.keys(attachCat).length > 0">
                                    <template v-if="key === 'H'">
                                        <h6 class="font-weight-bold col-12">{{ selectBoxTitle(key) }}</h6>
                                        <div class="col-12 row p-2">
                                            <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                                <div class="col-12 col-sm-4 mb-3">
                                                    <div class="attachmentLine">
                                                        <div class="mb-3">
                                                            <div title="Ver pdf" @click="openPDFModal(attachItem.urlTMP.fileUrl)" class="d-flex justify-content-between align-items-center title_container">
                                                                <h6 class="font-weight-bold m-0">{{ attachItem.detail.name || 'Sin nombre' }}</h6>
                                                                <i class="fa-regular fa-eye expand-icon"></i>
                                                            </div>
                                                            <hr class="my-1">
                                                            <div>
                                                                <b>Fecha de carga:</b> {{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') || '' }}
                                                            </div>
                                                            <div>
                                                                <b>Hora de carga:</b> {{ dayjs(attachItem.createdAt).format('HH:mm') || '' }}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 text-center">
                                                                <div v-if="attachItem.urlTMP.fileUrl">
                                                                    <object :data="attachItem.urlTMP.fileUrl" type="application/pdf" width="100%" height="350">
                                                                        <p>El visor de PDF no está disponible en tu navegador. Puedes <a :href="attachItem.urlTMP.fileUrl">descargar el PDF</a> en su lugar.</p>
                                                                    </object>
                                                                </div>
                                                                <div v-else>
                                                                    Sin PDF adjunto.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                </section>
                <section v-if="GetHistoryFiltred && viewMode === 'table'" class="mt-1 p-2">
                    <table class="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Orden N°</th>
                                <th>Nombre</th>
                                <th>Fecha de carga</th>
                                <th>Hora de carga</th>
                                <th>Categoría</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                                <template v-if="Object.keys(item.metadata).length > 0">
                                    <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                                        <template v-if="key === 'H'">
                                            <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                                <tr>
                                                    <td>{{ item.ordenId }}</td>
                                                    <td>{{ attachItem.detail.name || 'Sin nombre' }}</td>
                                                    <td>{{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') }}</td>
                                                    <td>{{ dayjs(attachItem.createdAt).format('HH:mm') }}</td>
                                                    <td>{{ selectBoxTitle(key) }}</td>
                                                    <td>
                                                        <i class="fa-regular fa-eye text-primary mr-2" @click="openPDFModal(attachItem.urlTMP.fileUrl)"></i>
                                                        <i class="fas fa-download text-success mr-2" @click="downloadAdjunto(attachItem)"></i>
                                                        <i class="fas fa-trash text-danger" @click="deleteAdjunto(attachItem)"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </section>
                <section v-else class="mt-3 d-flex justify-content-center">
                    <p class="text-muted">No se encontro historico de adjuntos</p>
                </section>
            </div>
        </box>
        <box title="Carga de adjuntos" icon="fa fa-upload" >
            <div class="row mt-3">
                <div class="col-12 col-sm-4">
                    <div>
                        <h5>Cargar Historial</h5>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <FileUploader
                                    url="orders/metadata-attachments/save"
                                    :accepted-mime="[
                                        'image/*',
                                        'application/pdf',
                                        'application/x-xls',
                                        'application/vnd.ms-excel',
                                        'application/msword',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/dicom', // .dcm
                                        'application/hdr',    // .hdr
                                        'application/nii',    // .nii
                                        'application/ktx',
                                    ]"
                                    remove-file-on-finish="1"
                                    :extra-data-form="laboratoriosUploadExtraDataForm"
                                    @fileSuccess="fileSuccess"
                                    ></FileUploader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </box>
        <div v-if="modalPDFViewer" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <h5 class="modal-title" id="modal-cita-titulo">PDF view</h5>
                            <button type="button" class="btn btn-danger" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <iframe id="pdfViewer" :src="medicDocUrl" style="width: 100%; height: 700px;" frameborder="0"></iframe> 
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>
<script>
import API from "src/core/Api";
import {mapGetters, mapActions} from "vuex";
import dayjs from "dayjs";
import Box from "src/modules/medico/views/fichas/Box.vue";
import FileUploader from "src/components/files/FileUploader.vue";
import Tools from "src/core/Tools";


// Componentes

export default {
    components: {
        FileUploader,
        Box,
    },
    props: [
        'type',
    ],
    data() {
        return {
            adjunto: {},
            laboratoriosUploadExtraDataForm: {
                orderId: 0,
                category: 'H',
            },
            medicDocUrl: '',
            modalPDFViewer: false,
            viewMode: 'table',
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
            GetHistoryFiltred: 'GetHistoryFiltred',
        })
    },
    watch: {
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

                this.laboratoriosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
            }
        }
    },
    mounted() {
        this.emitter.emit("medico-ficha-load",['attach'])
        this.FetchHistoryFiltred(['attach']);
    },
    methods: {
        ...mapActions({
            FetchHistoryFiltred: 'FetchHistoryFiltred',
        }),
        dayjs,
        generateGoogleViewerUrl(pdfUrl) {
            return `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`;
        },
        convertBlobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result.split(',')[1]);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        // subida de archivos
        fileSuccess(data) {
            this.gallery = Tools.pushToObject(this.gallery, data);
            this.emitter.emit("medico-ficha-refresh",['attach']);
        },
        downloadAdjunto(item) {
            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata-attachments/download', {
                    metadataId: item.id,
                }, function (response) {
                    if (response.data) {
                        window.open(response.data);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        sendAdjunto(item, tipoAdjunto){
          API.send('POST', 'orders/metadata-attachments/send', {
                metadataId: item.id,
                orderId: this.GetMedicoFichaActive.orden.id,
                title: tipoAdjunto
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        deleteAdjunto(item) {
            const self = this;
            // Guardo y luego emito el evento
            API.showConfirm('Espere', 'Si elimina este adjunto, no podrá recuperarlo. ¿Desea continuar?', function () {
                API.send('POST', 'orders/metadata-attachments/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh",['attach']);
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        handlerModal(){
            this.modalPDFViewer = !this.modalPDFViewer;
        },
        openPDFModal(url) {
            this.medicDocUrl = url;
            this.handlerModal();
        },
        selectBoxTitle(key) {
            if (key === 'H') {
                return 'Historial pre existente';
            }
        },
    },
};

</script>

<style scoped>
.expand-icon {
    transition: transform 0.2s;
    transform-origin: center;
}
.title_container:hover .expand-icon{
    transform: scale(1.2);
    color:#0ca7e0;
}
.modo-vista i {
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.2rem;
}
.icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
    border-radius: 4px;
}
</style>
