<script setup>
import { ref, onMounted, watch } from 'vue';
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Multiselect from 'vue-multiselect';
import * as XLSX from 'xlsx';

const medicos = ref([]);
const showRejectionModal = ref(false);
const rejectionReason = ref('');
const selectedLinkId = ref(null);
const tiposSeguros = ref([]);
const tiposPacientes = ref([]);
const tiposPlantillas = ref([]);
const selectedMedico = ref(null);
const selectedPlantilla = ref(null);
const selectedTipoSeguro = ref(null);
const selectedTipoPaciente = ref(null);
const monto = ref('');
const paciente = ref('');
const concepto = ref('');
const telefono = ref('');
const email = ref('');
const msg = ref('');
const activeTab = ref('generacion_link');
const estados = ['generacion_link', 'link_generado', 'link_completado', 'autorizado', 'pagado'];
const links = ref([]);

onMounted(() => {
    loadTiposSeguros();
    loadTiposPacientes();
    loadPlantilla();
    fetchLinks();
});

watch(activeTab, fetchLinks);

function openRejectionModal(linkId) {
    selectedLinkId.value = linkId;
    rejectionReason.value = "";
    showRejectionModal.value = true;
}
function closeRejectionModal() {
    showRejectionModal.value = false;
}
function confirmRejection() {
    if (!rejectionReason.value.trim()) {
        alert("Por favor, ingrese un motivo de rechazo.");
        return;
    }

    console.log("ID rechazado:", selectedLinkId.value);
    console.log("Motivo de rechazo:", rejectionReason.value);

    // Cerrar modal
    showRejectionModal.value = false;
}
function loadTiposSeguros() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'tipos-de-seguro', smallCatalog: 1 },
        (response) => response.status ? tiposSeguros.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}

function loadTiposPacientes() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'tipo-de-paciente', smallCatalog: 1 },
        (response) => response.status ? tiposPacientes.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}
function loadPlantilla() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'plantilla-link', smallCatalog: 1 },
        (response) => response.status ? tiposPlantillas.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}

function exportToExcel() {
    const dataToExport = links.value.map(link => ({
        ID: link.id,
        Médico: link.medico.descripcion,
        Tipo_Seguro: link.tipo_seguro.descripcion,
        Tipo_Paciente: link.tipo_paciente.descripcion,
        Monto: link.monto,
        Paciente: link.paciente,
        Teléfono: link.telefono,
        Email: link.email,
        Estado: link.estado
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Links Generados");
    XLSX.writeFile(workbook, "links_generados.xlsx");
}
function fetchLinks() {
    if(activeTab.value !== 'generacion_link'){
        API.send('POST', 'medico/links/obtener', { estado: activeTab.value },
            (response) => response.status ? links.value = response.data : API.showErrorAlert(response.msg),
            (error) => API.showErrorAlert(error.msg)
        );
    }

}

function searchMedicos(query) {
    if (!query) return;
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'catalogo-medicos', query },
        (response) => response.status ? medicos.value = response.data : null,
        (error) => API.showErrorAlert(error.msg)
    );
}

watch(selectedMedico, (medico) => {
    if (medico && medico.atributos) {
        const telefonoAttr = medico.atributos.find(attr => attr.nombre === 'telefono');
        const emailAttr = medico.atributos.find(attr => attr.nombre === 'correoElectronico');

        telefono.value = telefonoAttr ? telefonoAttr.valor : '';
        email.value = emailAttr ? emailAttr.valor : '';
    }
});

function generateLink() {
    if (!selectedMedico.value || !selectedMedico.value.id ||
        !selectedTipoSeguro.value || !selectedTipoSeguro.value.id ||
        !selectedTipoPaciente.value || !selectedTipoPaciente.value.id) {
        API.showErrorAlert("Debe seleccionar un médico, tipo de seguro y tipo de paciente");
        return;
    }

    API.send('POST', 'medico/links/generar', {
            medico_id: selectedMedico.value.id,
            tipo_seguro_id: selectedTipoSeguro.value.id,
            tipo_paciente_id: selectedTipoPaciente.value.id,
            monto: monto.value,
            paciente: paciente.value,
            concepto: concepto.value,
            telefono: telefono.value,
            email: email.value
        },
        function(response) {
            if (response.status) {
                API.showSuccessAlert('Link generado exitosamente');
            } else {
                API.showErrorAlert(response.msg);
            }
        },
        function(error) {
            API.showErrorAlert(error.msg);
        }
    );
}
</script>

<template>
    <setting-layout>
        <ul class="nav nav-tabs mb-3">
            <li class="nav-item" v-for="estado in estados" :key="estado">
                <button class="nav-link" :class="{ active: activeTab === estado }" @click="activeTab = estado">
                    {{ estado.replace('_', ' ').toUpperCase() }}
                </button>
            </li>
        </ul>

        <div v-if="activeTab === 'generacion_link'" class="card p-3">
            <h5 class="mb-3">Generar Link</h5>
            <div class="d-flex flex-wrap gap-2">
                <multiselect v-model="selectedPlantilla" :options="tiposPlantillas" label="descripcion" track-by="id" placeholder="Seleccione Hospital" ></multiselect>
                <multiselect v-model="selectedMedico" :options="medicos" :searchable="true" label="descripcion" track-by="id" placeholder="Buscar médico..." @search-change="searchMedicos"></multiselect>
                <multiselect v-model="selectedTipoPaciente" :options="tiposPacientes" label="descripcion" track-by="id" placeholder="Seleccione un tipo de paciente"></multiselect>
                <multiselect
                        v-if="selectedTipoPaciente && selectedTipoPaciente.descripcion.toLowerCase().includes('seguro')"
                        v-model="selectedTipoSeguro"
                        :options="tiposSeguros"
                        label="descripcion"
                        track-by="id"
                        placeholder="Seleccione un seguro">
                </multiselect>
                <input type="number" class="form-control" v-model="monto" placeholder="Monto" />
                <input type="text" class="form-control" v-model="paciente" placeholder="Nombre del paciente" />
                <textarea class="form-control" v-model="concepto" placeholder="Concepto"></textarea>
                <input type="text" class="form-control" v-model="telefono" disabled placeholder="Teléfono" />
                <input type="email" class="form-control" v-model="email" disabled placeholder="Correo" />
                <button class="btn btn-primary" @click="generateLink"> Generar Link </button>
            </div>
        </div>
        <div v-if="activeTab === 'link_generado'" class="card p-3">
            <h5 class="mb-3">Links Generados</h5>
            <div class="overflow-auto" style="max-height: 500px;">
                <table class="min-w-full border border-gray-200 text-sm">
                    <thead class="bg-gray-100 sticky top-0">
                    <tr>
                        <th class="p-1 border">ID</th>
                        <th class="p-1 border">Médico</th>
                        <th class="p-1 border">Seguro</th>
                        <th class="p-1 border">Paciente</th>
                        <th class="p-1 border">Tipo</th>
                        <th class="p-1 border">Monto</th>
                        <th class="p-1 border">Teléfono</th>
                        <th class="p-1 border">Email</th>
                        <th class="p-1 border">fecha creación</th>
                        <th class="p-1 border">fecha actualización</th>
                        <th class="p-1 border">Rechazos</th>
                        <th class="p-1 border">Notificado por</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="link in links" :key="link.id" class="hover:bg-gray-50">
                        <td class="p-1 border">{{ link.id }}</td>
                        <td class="p-1 border">{{ link.medico.descripcion }}</td>
                        <td class="p-1 border">{{ link.tipo_seguro.descripcion }}</td>
                        <td class="p-1 border">{{ link.paciente }}</td>
                        <td class="p-1 border">{{ link.tipo_paciente.descripcion }}</td>
                        <td class="p-1 border">{{ link.monto }}</td>
                        <td class="p-1 border">{{ link.telefono }}</td>
                        <td class="p-1 border">{{ link.email }}</td>
                        <td class="p-1 border">{{ link.creado_en }}</td>
                        <td class="p-1 border">{{ link.actualizado_en }}</td>
                        <td class="p-1 border">{{(typeof(link.serie) !== null)?1:0}}</td>
                        <td class="p-1 border"><i class="fa fa-sms"></i> <i class="fa fa-envelope"></i> <i class="fa fa-mobile-phone"></i></td>

                    </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-success mt-3" @click="exportToExcel">Descargar Excel</button>
        </div>
        <div v-if="activeTab === 'link_completado'" class="card p-3">
            <h5 class="mb-3">Links Completados</h5>
            <div class="row">
                <div class="col-12 col-md-6">

                </div>
            </div>
            <div class="overflow-auto" style="max-height: 500px;">
                <table class="min-w-full border border-gray-200 text-sm">
                    <thead class="bg-gray-100 sticky top-0">
                    <tr>
                        <th class="p-1 border">ID</th>
                        <th class="p-1 border">Médico</th>
                        <th class="p-1 border">Seguro</th>
                        <th class="p-1 border">Tipo</th>
                        <th class="p-1 border">Paciente</th>
                        <th class="p-1 border">Monto</th>
                        <th class="p-1 border">Serie</th>
                        <th class="p-1 border">Factura</th>
                        <th class="p-1 border">Cuenta bancaria</th>
                        <th class="p-1 border">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="link in links" :key="link.id" class="hover:bg-gray-50">
                        <td class="p-1 border"><input type="checkbox" class="form-control" /></td>
                        <td class="p-1 border">{{ link.medico.descripcion }}</td>
                        <td class="p-1 border">{{ link.tipo_seguro.descripcion }}</td>
                        <td class="p-1 border">{{ link.tipo_paciente.descripcion }}</td>
                        <td class="p-1 border">{{ link.paciente }}</td>
                        <td class="p-1 border">{{ link.monto }}</td>
                        <td class="p-1 border">{{ link.serie }}</td>
                        <td class="p-1 border">{{ link.factura }}</td>
                        <td class="p-1 border"><div class="row">

                            <div class="col-6">
                                <b>Tipo:</b> Monetaria
                            </div>
                            <div class="col-6">
                                <b>Moneda:</b>GTQ
                            </div>
                            <div class="col-6">
                                <b>Banco:</b>Banrural
                            </div>
                            <div class="col-6">
                                <b>Número:</b> 001-0054141-1
                            </div>
                        </div></td>
                        <td class="p-1 border">

                            <div class="btn-group btn-group-sm" role="group" aria-label="Acciones">
                                <button type="button" class="btn btn-success">
                                    <a class="btn btn-success" title="Descargar PDF" target="_blank" href="https://felpub.c.sat.gob.gt/verificador-web/publico/vistas/verificacionDte.jsf?tipo=autorizacion&numero=45B7F5C3-6390-4A3B-B43B-5FF38765EEC7&emisor=108561968&receptor=34843809&monto=8688.400000">
                                        <i class="fa fa-file-pdf"></i>
                                    </a>
                                </button>
                                <button type="button" class="btn btn-info">
                                    <i class="fas fa-check"></i>
                                </button>
                                <button class="btn btn-danger" @click="openRejectionModal(link.id)">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="btn-group btn-group-sm mt-3" role="group" aria-label="Exportar">
                <button class="btn btn-success" @click="exportToExcel">
                    <i class="fas fa-file-excel"></i> Excel
                </button>
                <button class="btn btn-danger" @click="exportToPdf">
                    <i class="fas fa-file-pdf"></i> PDF
                </button>
            </div>
        </div>
        <div v-if="activeTab === 'autorizado'" class="card p-3">
            <h5 class="mb-3">Links Autorizados</h5>
            <div class="row">
                <div class="col-12 col-md-6">

                </div>
            </div>
            <div class="table-responsive" style="max-height: 500px; overflow-x: auto;">
                <table class="table table-sm table-bordered">
                    <thead class="table-light sticky-top">
                    <tr>
                        <th class="p-1 text-center"><input type="checkbox" /></th>
                        <th class="p-1">Médico</th>
                        <th class="p-1">Seguro</th>
                        <th class="p-1">Tipo</th>
                        <th class="p-1">Paciente</th>
                        <th class="p-1">Retención IVA</th>
                        <th class="p-1">Retención ISR</th>
                        <th class="p-1">Monto</th>
                        <th class="p-1 border">Serie</th>
                        <th class="p-1 border">Factura</th>
                        <th class="p-1">Fecha pago</th>
                        <th class="p-1">Ref. Bancaria</th>
                        <th class="p-1">Monto depositado</th>
                        <th class="p-1">Tipo de pago</th>
                        <th class="p-1">Cuenta Monetaria</th>

                        <th class="p-1 text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="link in links" :key="link.id">
                        <td class="p-1 text-center"><input type="checkbox" class="form-check-input" /></td>
                        <td class="p-1">{{ link.medico.descripcion }}</td>
                        <td class="p-1">{{ link.tipo_seguro.descripcion }}</td>
                        <td class="p-1">{{ link.tipo_paciente.descripcion }}</td>
                        <td class="p-1">{{ link.paciente }}</td>
                        <td class="p-1">00.00</td>
                        <td class="p-1">00.00</td>
                        <td class="p-1">{{ link.monto }}</td>
                        <td class="p-1 border">{{ link.serie }}</td>
                        <td class="p-1 border">{{ link.factura }}</td>
                        <td class="p-1"><input class="form-control form-control-sm" type="date" /></td>
                        <td class="p-1"><input class="form-control form-control-sm" type="text" /></td>
                        <td class="p-1"><input class="form-control form-control-sm" type="text" /></td>
                        <td class="p-1">
                            <select class="form-select form-select-sm">
                                <option>Transferencia</option>
                                <option>Cheque</option>
                            </select>
                        </td>
                        <td class="p-1 border text-small">
                            <div class="row text-nowrap">
                                <div class="col-6">
                                    <b>Tipo:</b> Monetaria
                                </div>
                            </div>
                                <div class="row text-nowrap">
                                <div class="col-6">
                                    <b>Moneda:</b>GTQ
                                </div>
                                </div>
                                <div class="row text-nowrap">
                                <div class="col-6">
                                    <b>Banco:</b>Banrural
                                </div>
                                </div>
                                <div class="row text-nowrap">
                                <div class="col-6">
                                    <b>Número:</b> 001-0054141-1
                                </div>
                                </div>

                        </td>
                        <td class="p-1 text-center">
                            <div class="btn-group btn-group-sm">
                                <a class="btn btn-success" title="Descargar PDF" target="_blank" href="#">
                                    <i class="fa fa-file-pdf"></i> ver factura
                                </a>
                                <button class="btn btn-info" title="Pagar">
                                    <i class="fa fa-dollar-sign"></i> pagar
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="btn-group btn-group-sm mt-3" role="group" aria-label="Exportar">
                <button class="btn btn-success" @click="exportToExcel">
                    <i class="fas fa-file-excel"></i> Excel
                </button>
                <button class="btn btn-danger" @click="exportToPdf">
                    <i class="fas fa-file-pdf"></i> PDF
                </button>
            </div>
        </div>
        <div v-if="activeTab === 'pagado'" class="card p-3">
            <h5 class="mb-3">Links Generados</h5>
            <div class="row">
                <div class="col-12 col-md-6">

                </div>
            </div>
            <div class="table-responsive" style="max-height: 500px; overflow-x: auto;">
                <table class="table table-sm table-bordered text-nowrap">
                    <thead class="table-light sticky-top">
                    <tr>
                        <th class="p-1 text-center"><input type="checkbox" /></th>
                        <th class="p-1">Médico</th>
                        <th class="p-1">Seguro</th>
                        <th class="p-1">Tipo</th>
                        <th class="p-1">Paciente</th>
                        <th class="p-1">Retención IVA</th>
                        <th class="p-1">Retención ISR</th>
                        <th class="p-1">Monto</th>
                        <th class="p-1">Ref. Bancaria</th>
                        <th class="p-1">Monto depositado</th>
                        <th class="p-1">Tipo de pago</th>
                        <th class="p-1">Fecha de Pago</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="link in links" :key="link.id">
                        <td class="p-1 text-center">{{link.ID}}</td>
                        <td class="p-1">{{ link.medico.descripcion }}</td>
                        <td class="p-1">{{ link.tipo_seguro.descripcion }}</td>
                        <td class="p-1">{{ link.tipo_paciente.descripcion }}</td>
                        <td class="p-1">{{ link.paciente }}</td>
                        <td class="p-1">00.00</td>
                        <td class="p-1">00.00</td>
                        <td class="p-1">{{ link.monto }}</td>
                        <td class="p-1">{{link.refBancaria}}</td>
                        <td class="p-1">{{link.montoDepositado}}</td>
                        <td class="p-1">
                            {{link.tipoPago}}
                        </td>
                        <td class="p-1">
                            {{link.actualizado_en}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="btn-group btn-group-sm mt-3" role="group" aria-label="Exportar">
                <button class="btn btn-success" @click="exportToExcel">
                    <i class="fas fa-file-excel"></i> Excel
                </button>
                <button class="btn btn-danger" @click="exportToPdf">
                    <i class="fas fa-file-pdf"></i> PDF
                </button>
            </div>
        </div>
        <!-- MODAL PARA RECHAZO -->

        <div v-if="showRejectionModal" class="modal fade show" id="rejectionModal" style="display: block" tabindex="-1" aria-labelledby="rejectionModalLabel" aria-hidden="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="rejectionModalLabel">Motivo de Rechazo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label for="rejectionReason" class="form-label">Ingrese el motivo de rechazo:</label>
                        <textarea id="rejectionReason" class="form-control" v-model="rejectionReason" rows="3"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="showRejectionModal = false;">Cancelar</button>
                        <button type="button" class="btn btn-danger" @click="confirmRejection">Confirmar Rechazo</button>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
