<template>
    <div class="order-search" v-show="showProductSearchBox" v-on:click.self="hideSearch" id="modal-products" tabindex="-1" aria-labelledby="modal-products-titulo" aria-hidden="true">
        <div class="modal-dialog modal-xl" style="max-height: 95vh;">
            <div class="modal-content" style="max-height: 95vh;">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-cita-titulo">Buscar productos y servicios</h5>
                    <button type="button" class="close" @click="hideSearch" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                    <div class="search-section col-12 col-md-4 position-relative">
                        <div class="mt-2">
                            <label for="searchByName">Búsqueda por nombre</label>
                            <div class="input-group">
                                <input id="searchByName" type="text" class="form-control searchField" placeholder="Búsqueda de productos por nombre" v-model="searchTerm" v-on:keyup.enter="onEnter"/>
                                <div class="input-group-append" @click="searchTerm = ''">
                                    <span class="input-group-text" id="basic-addon2"><i class="fa fa-times"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2" v-if="locations.length > 0">
                            <label for="filterByLocation">Filtro por locación</label>
                            <div class="input-group">
                                <select id="filterByLocation" class="form-control" v-model="locacion" @change="search">
                                    <option value="" selected>Seleccionar locacion</option>
                                    <option v-for="location in locations" :key="location.id" :value="location.id">
                                        {{ location.nombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div v-show="this.tipoOrden === 'recurrente'" class="mt-4 text-center alert alert-info">
                            <i class="fa fa-info-circle"></i> Se muestran solo productos habilitados para recurrencia
                        </div>
                        <div class="clientSearchButtons text-center mb-2 mt-3">
                            <button type="button" class="btn btn-primary w-100" @click="search">
                                <i class="fa fa-search"></i> <span>Buscar</span>
                            </button>
                        </div> 
                        <div class="shopping-bag-icon cursor-pointer" :class="{'shake': shake}" v-if="searchResults.length > 0" @click="hideSearch" title="Ver productos seleccionados">
                            <i :class="['fa-solid', 'fa-bag-shopping', 'fa-3x', selectedProductsCount > 0 ? 'text-success' : 'text-muted']">
                                <span class="badge badge-pill badge-danger position-absolute" style="top: -7px; right: -7px;">{{ selectedProductsCount }}</span>
                            </i>
                        </div>
                    </div>
                    <div class="results-section col-12 col-md-8 search-response row">
                            <div v-if="searchResults.length === 0" class="col-12 text-center text-muted">
                                No hay productos para mostrar.
                            </div>
                            <div class="col-12" v-for="item in searchResults" :key="item.id">
                                <div class="media item-card position-relative">
                                    <div class="media-body">
                                        <div class="row">
                                            <div class="col-4 col-md-2 d-flex align-items-center justify-content-center">
                                                <img v-if="item.thumbnail !== ''" :src="item.thumbnail" class="img-thumbnail">
                                                <i v-else class="fa fa-image fa-3x"></i>
                                            </div>
                                            <div class="col-8 col-md-10">
                                                <span class="text-primary">{{item.nombre}}</span><br/>
                                                <span class="text-dark">SKU: {{item.sku}}</span><br/>
                                                <div v-if="parseInt(item.recurrente) === 1">
                                                    <span class="text-success"><i class="fas fa-sync mr-1"></i> Recurrente</span><br/>
                                                </div>
                                                <div v-if="item.locacion">
                                                    Locacion: <span class="font-weight-bold">{{item.locacion.nombre}}</span><br/>
                                                </div>
                                                <div v-if="item.onSale">
                                                    Precio: <span class="font-weight-bold">{{item.precioOnSale}}</span><br/>
                                                </div>
                                                <div v-else>
                                                    Precio: <span class="font-weight-bold">{{item.precio}}</span><br/>
                                                </div>
                                                <span>Disponibles:</span> <span class="text-dark">{{ item.stock }}</span>
                                                <div class="d-block d-sm-none add-option btn btn-outline-success btn-sm mt-2" @click="selectProduct(item)">
                                                    <i class="fa fa-plus"></i> Agregar
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-none d-sm-block add-option btn btn-outline-success" @click="selectProduct(item)">
                                        <i class="fa fa-plus"></i> Agregar
                                    </div>
                                    <div v-if="isSelected(item)" class="badge badge-primary selected-badge position-absolute" title="Item seleccionado">
                                        <i class="fa fa-shopping-bag text-white"></i>
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
}

.shake {
    animation: shake 0.4s;
}

.results-section {
    min-height: 70vh;
    max-height: 70vh; 
    overflow-y: auto; 
    border-left: 1px solid #ddd; 
    border-radius: 2px; 
    padding: 5px;
    margin-left: 5px;
}

.item-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
}

.item-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-option {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.add-option:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.img-thumbnail {
    display: block;
    margin: auto;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25em 0.4em;
}

.shopping-bag-icon {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.selected-badge {
    top: -3px;
    left: -2px;
}

@media (max-width: 767px) {
    .shopping-bag-icon {
        position: relative;
        bottom: auto;
        left: auto;
        margin-top: 20px;
        display: flex;
        justify-content: end;
    }
}

</style>

<script>

import API from "src/core/Api";
import {mapActions} from "vuex";
import Tools from "../../../core/Tools";
import bellSound from "src/assets/Audio/pop.mp3";
export default {
    name: "order-search",
    components: {},
    props: {
        showProductSearchBox: {
            type: Boolean,
            default: false // Valor predeterminado de false
        },
        tipoOrden: {
            type: String,
            default: false // Valor predeterminado 'default'
        },
        tipoProductos: {
            type: Array,
            default: () => [] // Valor predeterminado es un array vacío
        },
        disableNotify: {
            type: Boolean,
            default: false // Valor predeterminado de false
        },
        redirectLink: {
            type: String,
            default: '' // Valor predeterminado es una cadena vacía
        }
    },
    data() {
        return {
            filterByType: '',
            searchResults: {},
            pageSelected: 1,
            limit: 20,
            filter: 0,
            searchTerm: '',
            locacion: '',
            locations:[],
            selectedProductsCount: 0,
            selectedProducts: [],
            shake: false,
            bellSound: new Audio(bellSound)
        };
    },
    watch: {
        showProductSearchBox: function (val) {
            if (val) {
                this.search();
                this.loadLocations()
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        showClientSearch: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
    },
    mounted() {
        this.filterByType = (!this.tipoProductos) ? 'all' : this.tipoProductos;
        this.bellSound.volume = 0.15; // Set volume to half of the original
        //this.search();
    },
    methods: {
        ...mapActions({
            OrderAddProductToPos: 'OrderAddProductToPos',
        }),
        loadLocations() {
          const self = this;
          API.send('GET', '/locaciones/all', {},
              function (data) {
                  if (data.status) {
                      self.locations = data.data;
                  }
              },
              function (data) {
                  API.showErrorAlert(data.msg);
              })
        },
        hideSearch: function () {
            this.$emit('update:showProductSearchBox', false);
            this.searchTerm = '';
        },
        onEnter: function() {
            this.search();
        },
        search() {
            const self = this;

            if (this.locacion) {
                API.send('GET', `products/by-location/1/${self.pageSelected}/${self.limit}/${this.locacion}/${self.searchTerm}`, {},
                    function (data) {
                        if (data.status) {
                            self.searchResults = data.data.productos;
                            if (self.searchResults.length < 9) {
                                self.pagination = [];
                            } else {
                                self.pagination = data.data.paginas;
                            }
                        } else {
                            self.searchResults = [];
                            self.pagination = [];
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            } else {
                let urlToSearch = '';

                if (this.tipoOrden === 'directo') {
                    urlToSearch = 'products/all/1/'+self.pageSelected+'/'+self.limit+'/'+self.filter;

                    if (this.searchTerm !== '') {
                        urlToSearch = 'products/search/'+this.searchTerm;
                    }
                }
                else {
                    urlToSearch = 'products/all/recurrente/1';

                    if (this.searchTerm !== '') {
                        urlToSearch = 'products/search/recurrente/'+this.searchTerm;
                    }
                }

                const dataSearch = {
                    term: this.searchTerm,
                    page: self.pageSelected,
                    perPage: self.limit,
                    filter: self.filter,
                    filterByType: self.filterByType,
                }

                API.send('POST', 'products/do-search', dataSearch,
                    function (response) {
                        if (response.status) {
                            if(Tools.isEmptyString(response.data.productos)){
                                self.searchResults = response.data;
                            }
                            else{
                                self.searchResults = response.data.productos;
                            }

                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            }
        },
        selectProduct: function (product) {
            if(this.redirectLink){
                this.$router.push(this.redirectLink + product.id)
            }
            else{
                this.$emit('productSelected', product);
                this.$store.dispatch('OrderAddProductToPos', product);
                this.selectedProducts.push(product);
                this.selectedProductsCount++;
                this.triggerShake();
                this.bellSound.play();
            }

        },
        isSelected(product) {
            return this.selectedProducts.some(selected => selected.id === product.id);
        },
        triggerShake() {
            this.shake = true;
            setTimeout(() => {
                this.shake = false;
            }, 400);
        }
    },
};
</script>
