<template>
    <div v-if="showClientSearch" class="order-search order-search-clients" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true" v-on:click.self="hideSearch">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-cita-titulo">Cliente - Cobro o facturación</h5>
                    <button type="button" class="close" @click="hideSearch" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="search-section col-12 col-md-4 position-relative">
                            <div class="mt-2">
                                <label for="searchClient">Búsqueda de cliente</label>
                                <div class="input-group">
                                    <input id="searchClient" type="text" class="form-control searchField" placeholder="Escriba nombre, NIT o correo electrónico..." v-model="searchTerm" v-on:keyup.enter="onEnter"/>
                                    <div class="input-group-append" @click="searchTerm = ''">
                                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-times"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="operation !== 'creation' && operation !== 'edition'" class="mt-2 text-center alert alert-info">
                                <i class="fa fa-info-circle"></i><small>Recomendamos realizar una búsqueda para validar la existencia previa del cliente antes de crear uno nuevo</small>
                            </div>
                            <div class="clientSearchButtons text-center mt-2">
                                <button type="button" class="btn btn-primary w-100" @click="search">
                                    <i class="fa fa-search"></i> <span>Buscar</span>
                                </button>
                            </div> 
                            <div class="clientCfButtons text-center mt-2">
                                <button type="button" class="btn btn-outline-primary w-100" @click="selectCF">
                                    <i class="fas fa-user-secret"></i> <span>Cliente CF</span>
                                </button>
                            </div>
                        </div>
                        <div class="results-section col-12 col-md-8 search-response row">
                            <div v-if="operation === 'creation' || operation === 'edition'" class="clientFastCreator">
                                <hr>
                                <div class="text-center">
                                    <h6 class="font-weight-bold text-primary mb-4">Creación de cliente</h6>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group mb-1">
                                            <label>NIT (*)</label>
                                            <div class="input-group cfField">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <input type="checkbox" id="cfUse" aria-label="CF o consumidor final" class="mr-2" v-model="staticCF"/>
                                                        <label for="cfUse">CF</label>
                                                    </div>
                                                </div>
                                                <input type="text" class="form-control" placeholder="Escribe aquí" name="taxId" v-model="creationTaxId" :readonly="staticCF"/>
                                            </div>
                                            <div class="text-center mb-3">
                                                <small>Sin espacios ni guiones</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label>Nombres</label>
                                            <input type="text" class="form-control" placeholder="Escribe aquí" name="nombres" v-model="creationNombres"/>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label>Apellidos</label>
                                            <input type="text" class="form-control" placeholder="Escribe aquí" name="apellidos" v-model="creationApellidos"/>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label>Dirección</label>
                                            <input type="text" class="form-control" placeholder="Escribe aquí" name="direccion" v-model="creationDireccion"/>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label>Correo electrónico</label>
                                            <input type="text" class="form-control" placeholder="Escribe aquí" name="email" v-model="creationEmail"/>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label>Teléfono de contacto</label>
                                            <input type="text" class="form-control" placeholder="Escribe aquí" name="phone" v-model="creationPhone"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-success btn-sm mr-3" type="button" @click="registerClient"><i class="fas fa-check"></i></button>
                                    <button class="btn btn-outline-danger btn-sm" type="button" @click="operation = 'search'"><i class="fas fa-chevron-up"></i></button>
                                </div>
                                <hr>
                            </div>
                            <div v-if="operation === 'search'" class="search-response row">
                                <div v-if="!searchResults.length && searched" class="col-12 text-center text-muted">
                                    No hay productos para mostrar.
                                </div>
                                <div class="col-12" v-for="item in searchResults" :key="item.id">
                                    <div class="media item-card position-relative">
                                        <div class="icon-container d-flex align-items-center justify-content-center mr-2">
                                            <i class="iconSearchItem fas fa-user-circle"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1">
                                                {{item.nombres}} {{item.apellidos}}
                                                <span v-if="!item.taxId">
                                                    (Sin NIT)
                                                </span>
                                                <span v-if="item.taxId">
                                                    (NIT: {{item.taxId}})
                                                </span>
                                            </h6>
                                            <div>
                                                Correo electrónico: <span class="text-primary">{{item.email}}</span>
                                            </div>
                                            <div>
                                                Teléfono: <span class="text-primary">{{item.telefono}}</span>
                                            </div>
                                        </div>
                                        <div v-show="operation === 'search'" class="action-icons d-flex flex-column align-items-center">
                                            <button class="add-option btn btn-success btn-xs mb-2" @click="select(item)">
                                                <i class="fas fa-check-circle"></i> Seleccionar
                                            </button>
                                            <button class="edit-option btn btn-warning btn-xs" @click="edit(item)">
                                                <i class="fas fa-pencil-square"></i> Editar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.results-section {
    min-height: 70vh;
    max-height: 70vh; 
    overflow-y: auto; 
    overflow-x: hidden; /* Prevent horizontal scroll */
    border-left: 1px solid #ddd; 
    border-radius: 2px; 
    padding: 5px;
    margin-left: 5px;
}

.item-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 7px; /* Reduced margin */
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
    display: flex;
    align-items: center;
}

.item-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-container {
    width: 50px;
    height: 50px;
}

.iconSearchItem {
    font-size: 2rem;
    color: #6c757d;
}

.add-option {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.add-option:hover {
    background-color: #218838;
    border-color: #1e7e34;
}

.edit-option {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107;
}

.edit-option:hover {
    background-color: #e0a800;
    border-color: #d39e00;
}

.img-thumbnail {
    display: block;
    margin: auto;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25em 0.4em;
}

@media (max-width: 767px) {
    .shopping-bag-icon {
        position: relative;
        bottom: auto;
        left: auto;
        margin-top: 20px;
        display: flex;
        justify-content: end;
    }
}
</style>

<script>
import API from "src/core/Api";
import {mapActions} from "vuex";
import Tools from "src/core/Tools";

export default {
    name: "order-search",
    components: {},
    props: ['showClientSearch', 'clientSelected','inline'],
    data() {
        return {
            operation: 'search',
            searchTerm: '',
            searched: false,
            searchResults: {},
            searchType: '',

            // form
            staticCF: false,
            creationId: 0,
            creationTaxId: '',
            creationEmail: '',
            creationNombres: '',
            creationApellidos: '',
            creationPhone: '',
            creationDireccion: '',
        };
    },
    watch: {
        searchTerm: function (val) {
            const isEmail = String(val)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );

            if (val !== '') {
                if (isEmail) {
                    this.searchType = 'email';
                }
                else {
                    this.searchType = 'tax_id';
                }
            }
            else {
                this.searchType = '';
            }
            this.searched = false;
        },
        staticCF: function (val) {
            if (val) {
                this.creationTaxId = 'CF';
            }
            else {
                this.creationTaxId = '';
            }
        },
        creationTaxId: function (val) {
            if (val.length > 3) {
                this.staticCF = false;
            }
        },
        showClientSearch: function (val) {
            const self = this;

            if (val) {
                this.$nextTick(() => {
                    if (typeof self.$refs["searchTerm"] !== 'undefined') self.$refs["searchTerm"].focus()
                })
            }
        }
    },
    methods: {
        ...mapActions({
            OrderPosClientSelect: 'OrderPosClientSelect',
        }),
        hideSearch: function () {
            this.searchResults = {};
            this.$emit('update:showClientSearch', false);
            this.searchTerm = '';
            this.operation = 'search';
        },
        onEnter: function() {
            this.search();
        },
        clearCreationForm() {
            this.creationTaxId = '';
            this.creationEmail = '';
            this.creationNombres = '';
            this.creationApellidos = '';
            this.creationPhone = '';
            this.creationDireccion = '';
        },
        search() {
            const self = this;

            if (this.searchTerm !== '') {
                let searchUrl = '';
                const encodedSearchTerm = this.searchTerm;
                if (this.searchType === 'email') {
                    searchUrl = 'clients/search/term';
                }
                else {
                    searchUrl ='clients/search/term';
                }

                // clear creation form
                self.clearCreationForm();

                API.send('POST', searchUrl, {term: encodedSearchTerm},
                    function (response) {
                        if (response.status) {
                            self.searchResults = response.data;
                            if (response.data.length === 0) {
                                self.operation = 'creation';

                                if (self.searchType === 'email') {
                                    self.creationEmail = self.searchTerm;
                                }
                                else {
                                    self.creationNombres = self.searchTerm; // Pre-fill the name
                                }
                            }
                            else {
                                self.operation = 'search';
                            }
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                        self.searched = true;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.searched = true;
                    })
            }
            else {
                API.showErrorNotify('Ingresa un término para buscar');
            }
        },
        registerClient() {

            if (!this.staticCF && this.creationTaxId.length < 4) {
                API.showErrorNotify('Ingrese un nit válido');
            }
            else {

                // if client has nit or email
                if ((this.creationTaxId !== '' && this.creationTaxId.toUpperCase() !== 'CF') || this.creationEmail !== '') {

                    if (Tools.isEmptyString(this.creationNombres, 2)) {
                        API.showErrorNotify('Debe ingresar un nombre válido');
                        return false;
                    }
                    if (Tools.isEmptyString(this.creationApellidos, 2)) {
                        API.showErrorNotify('Debe ingresar un apellido válido');
                        return false;
                    }
                    if (Tools.isEmptyString(this.creationDireccion, 4)) {
                        API.showErrorNotify('Debe ingresar una dirección');
                        return false;
                    }

                    let method = 'POST';
                    let url = 'clients/register/pos';

                    const self = this;
                    API.send(method, url, {
                            "taxId": self.creationTaxId,
                            "email": self.creationEmail,
                            "nombres": self.creationNombres,
                            "apellidos": self.creationApellidos,
                            "telefono": self.creationPhone,
                            "direccion": self.creationDireccion,
                            "id": self.creationId,
                        },
                        function (response) {

                            if (response.status) {
                                API.showSuccessAlert(response.msg);
                                self.select(response.data[0]);
                                self.clearCreationForm();
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }
                        },
                        function (data) {
                            API.showErrorNotify(data.msg);
                        })
                }
                else {
                    // es CF
                    this.select({
                        "id": 0,
                        "email": this.creationEmail,
                        "taxId": "CF",
                        "nombres": this.creationNombres,
                        "apellidos": this.creationApellidos,
                        "direccion": this.creationDireccion,
                        "telefono": this.creationPhone,
                    });
                }
            }

        },
        select(value) {
            this.$emit('clientSelected', value);
            this.$store.dispatch('OrderPosClientSelect', value);
            this.hideSearch();
        },
        edit(value) {
            this.creationTaxId = (value.taxId !== '') ? value.taxId : '';
            this.creationEmail = (value.email !== '') ? value.email : '';
            this.operation = 'edition';
            this.creationId = value.id;
            this.creationNombres = value.nombres;
            this.creationApellidos = value.apellidos;
            this.creationPhone = value.telefono;
            this.creationDireccion = value.direccion;
        },
        selectCF() {

            // es CF
            /*this.select({
                "id": 0,
                "email": "",
                "taxId": "CF",
                "nombres": 'Consumidor',
                "apellidos": 'Final',
                "direccion": 'Ciudad',
                "telefono": "",
            });*/

            this.searched = true;
            this.operation = 'creation';
            this.staticCF = true;
            /*this.operation = 'creation'
            this.staticCF = true;*/
        },
    },
};
</script>
