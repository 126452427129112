import localDisk from 'src/localDisk';
import {CONFIG} from "src/config";
import API from "src/core/Api";
import {store} from "src/store";

export const authState = {
    state: {
        token: localDisk.read('authToken'),
        userInfo: localDisk.read('authUserInfo'),
        storeInfo: localDisk.read('storeInfo'),
        whiteLabelInfo: localDisk.read('whiteLabelInfo'),
        wlcache: localDisk.read('wlcache'),
        stcache: localDisk.read('stcache'),
    },
    mutations: {
        AuthSetToken(state, data) {
            if ( data ) {
                state.token = data
                localDisk.write('authToken', data)
            }
            else {
                localDisk.write('authToken', null)
            }
        },
        SetUserInfo(state, data) {
            if ( data ) {
                state.userInfo = data
                localDisk.write('authUserInfo', data)
            }
            else {
                localDisk.write('authUserInfo', null);
            }
        },
        SetStoreInfo(state, data) {
            if ( data ) {
                state.storeInfo = data
                localDisk.write('storeInfo', data)
            }
            else {
                localDisk.write('storeInfo', null);
            }
        },
        SetWlCache(state, data) {
            if ( data ) {
                state.cache = data
                localDisk.write('wlcache', data)
            }
            else {
                localDisk.write('wlcache', null);
            }
        },
        SetStoreCache(state, data) {
            if ( data ) {
                state.cache = data
                localDisk.write('stcache', data)
            }
            else {
                localDisk.write('stcache', null);
            }
        },
        SetWhiteLabelInfo(state, data) {
            if ( data ) {
                state.whiteLabelInfo = data
                localDisk.write('whiteLabelInfo', data)
            }
            else {
                localDisk.write('whiteLabelInfo', null);
            }
        },
    },
    getters: {
        AuthIsLogged: state => {
            return !!state.token;
        },
        AuthGetToken: state => {
            return state.token;
        },
        AuthGetUserInfo: state => {
            if (state.userInfo) {
                return state.userInfo;
            }
            else {
                return {};
            }
        },
        Access: (state) => (access) => {
            if (typeof state.userInfo !== 'undefined') {
                if (typeof state.userInfo.acc !== 'undefined') {
                    if (typeof state.userInfo.acc['panel'] !== 'undefined') {
                        return (typeof state.userInfo.acc['panel'][access] !== 'undefined' && state.userInfo.acc['panel'][access]);
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },
        StoreInfo: state => {
            if (state.storeInfo) {
                return state.storeInfo;
            }
            else {
                return {}
            }
        },
        WlCache: state => {
            if (state.wlcache) {
                return state.wlcache;
            }
            else {
                return ''
            }
        },
        StCache: state => {
            if (state.stcache) {
                return state.stcache;
            }
            else {
                return ''
            }
        },
        whiteLabelInfo: state => {
            if (state.whiteLabelInfo) {
                return state.whiteLabelInfo;
            }
            else {
                return {};
            }
        },
        storeAccessQuota: state => {
            if (state.userInfo) {
                if (state.userInfo.aq) {
                    return state.userInfo.aq;
                }
                else {
                    return {};
                }
            }
            else {
                return {};
            }
        },
    },
    actions: {
        WhiteLabelDomain: ({commit}, payload) => {
            commit('SetWhiteLabelDomain', payload);
        },

        CheckSession: ({commit, getters}, payload) => {

            store.dispatch('coreShowLoading');
            const token = getters.AuthGetToken;
            let version = getters.WlCache + "-" + getters.StCache;

            fetch(CONFIG.getWsUrl('users/check-session'), {
                method: "POST",
                body: JSON.stringify({
                    v: version,
                    d: payload.domain,
                    t: token,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "X-Any-Wl": (getters.whiteLabelInfo) ? getters.whiteLabelInfo.id : 0,
                },
            })
                .then(response => response.json())
                .then(response => {

                    if (typeof response.status !== 'undefined') {

                        // guardo cache
                        let whitelabelCache = getters.WlCache;
                        let storeCache = getters.StCache;
                        if (typeof response.data.wli.version !== 'undefined') {
                            whitelabelCache = response.data.wli.version;
                        }
                        if (typeof response.data.si.version !== 'undefined') {
                            storeCache = response.data.si.version;
                        }

                        if (response.data.wli !== 'cached') {
                            commit('SetWhiteLabelInfo', response.data.wli);
                        }
                        if (response.data.si !== 'cached') {
                            commit('SetStoreInfo', response.data.si);
                        }
                        commit('SetWlCache', whitelabelCache);
                        commit('SetStoreCache', storeCache);

                        // si la respuesta no es valida
                        if (response.status === 0 || !response.status) {
                            commit('SetWhiteLabelInfo', null);
                            commit('SetStoreInfo', null);
                            commit('SetWlCache', null);
                            commit('SetStoreCache', null);
                        }

                        // AUTH - En cada request me vuelo el token, si está logueado lo reemplazo
                        commit('AuthSetToken', null);
                        commit('SetUserInfo', null);

                        if (response.data.u && typeof response.data.u.id !== 'undefined') {
                            if (response.data.u.id > 0) {
                                // si es valido, guardo la info y continuo bien
                                commit('AuthSetToken', token);
                                commit('SetUserInfo', response.data.u);
                            }
                        }

                        // Callback
                        if (typeof payload.callback === 'function') {
                            payload.callback(response.data);
                        }

                        store.dispatch('coreHideLoading');
                    }
                    else {
                        // deslogueo
                        commit('AuthSetToken', null);
                        commit('SetWhiteLabelInfo', null);
                        commit('SetStoreInfo', null);
                        commit('SetWlCache', null);
                        commit('SetStoreCache', null);
                        if (typeof payload.routerGuard === 'function') payload.routerGuard('signin');
                        store.dispatch('coreHideLoading');
                    }
                })
                .catch(() => {
                    //console.log(e);
                    store.dispatch('coreHideLoading');
                    //window.location.href = "/store/404";
                });
        },
        AuthLogout: () => {
            localDisk.clearDisk();
            sessionStorage.clear();
        }
    }
}
