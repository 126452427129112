import { createRouter, createWebHistory } from 'vue-router'
import { store } from "../store";
import { CONFIG } from "../config";
import AndroidBridge from "../core/AndroidBridge";

// load routes files
import dashboardRoutes from '../modules/dashboard/routes';
import authRoutes from '../modules/auth/routes';
import userRoutes from '../modules/user/routes';
import Order from "../modules/order/routes";
import productsRoutes from "../modules/product/routes";
import ClientsRoutes from "../modules/client/routes";
import FormsRoutes from "../modules/form/routes";
import MedicalRoutes from "../modules/medico/routes";
import MySiteRoutes from "../modules/mysite/routes";
import ReportsRoutes from "../modules/reports/routes";
import ShortLinksRoutes from "../modules/shortLink/routes";
import ColasRoutes from "../modules/colas/routes";

// public routes
import PublicAnySubscriptions from "../public/anysubscriptions.com/routes";
import Tools from "src/core/Tools";

const routesCore = [
];

const routes = routesCore.concat(authRoutes, dashboardRoutes, userRoutes, Order, productsRoutes, PublicAnySubscriptions, ClientsRoutes, MySiteRoutes, FormsRoutes, ReportsRoutes, MedicalRoutes, ShortLinksRoutes, ColasRoutes);

const router = createRouter({
    history: createWebHistory(),
    //base: process.env.BASE_URL,
    routes,
})

// start android bride and ios
const androidBridge = new AndroidBridge();

const fullDomain = window.location.href;
const parsedUrl = new URL(fullDomain);

// domain with subdomain
let domain = parsedUrl.host;

if (androidBridge.appEnabled()) {
    if (!Tools.isEmptyString(store.getters.AppConfig)) {
        domain = store.getters.AppConfig;
    }
    else {
        domain = false;
    }
}

router.beforeEach((to, from, next) => {
    const isPublicRoute = to.matched.some(record => record.meta.isPublic);
    //const isLoginClient = to.matched.some(record => record.meta.loginClient);

    if (to.path === '/404' || to.path === '/store/404' || to.path === '/app-config') {
        next();
        return false;
    }
    else {
        if (domain) {
            store.dispatch('CheckSession', {
                callback: (response) => {

                    // Si estoy en la web y no en app
                    if (!androidBridge.appEnabled()) {

                        // si no es un whitelabel o tienda valida
                        if (parseInt(response.a) === 0 && parseInt(response.s) === 0) {
                            if (to.path === '/' && typeof store.getters.whiteLabelInfo.multiVendor !== 'undefined' && store.getters.whiteLabelInfo.multiVendor === '') {
                                window.location.href = 'https://app.' + store.getters.whiteLabelInfo.domain
                            }
                            else {
                                window.location.href = "/404";
                            }
                        }

                        // si va al panel de control
                        if (parseInt(response.a) === 1) {
                            if (to.path === '/' && !store.getters.AuthIsLogged) next('signin');
                            if (to.path === '/' && store.getters.AuthIsLogged) next('dashboard');
                        }
                    }
                    else {
                        if (to.path === '/' && !store.getters.AuthIsLogged) next('signin');
                        if (to.path === '/' && store.getters.AuthIsLogged) next('dashboard');
                    }

                    // si no tengo una ruta pública
                    if (!store.getters.coreIsLockedLoading) {
                        store.dispatch('coreHideLoading');
                    }

                    if (!isPublicRoute) {
                        // valido si estoy logueado
                        if (store.getters.AuthIsLogged) {
                            next();
                        }
                        else {
                            next('signin');
                        }
                    }
                    else {
                        next();
                    }
                },
                domain: domain
            });
        }
        else {
            if (to.path === '/') {
                next('app-config');
            }
        }
    }
});

export default router;
