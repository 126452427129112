//import History from "./views/History";
import Account from "./views/Account";
import AccountConfig from "./views/Configuracion";
import SettingsPreferences from "./views/Preferences";
import MiConsumo from "./views/MiConsumo.vue";
import MiSaldo from "./views/MiSaldo";
import Payment from "./views/Payment";
import Addresses from "./views/Addresses";
import Affiliation from "./views/Affiliations/Affiliation";
import AffiliationForm from "./views/Affiliations/AffiliationForm";
import Personal from "./views/Personal/Personal";
import Roles from "./views/Personal/Roles";
import RoleForm from "./views/Personal/RoleForm";
import AppTerceros from "./views/Personal/AppTerceros.vue";
import AppTercerosForm from "./views/Personal/AppTercerosForm.vue";

import PersonalForm from "./views/Personal/PersonalForm";
import SendForms from "./views/Personal/CreateAndSendForm.vue";
import Solicitudes from "./views/Solicitudes/Affiliation";
import Locaciones from "./views/Locaciones";
import Shipping from "./views/Shipping";
import SolicitudesForm from "./views/Solicitudes/AffiliationForm";
import ControlComerciosListado from "./views/ControlComerciosListado.vue";
import ControlComercio from "./views/ControlComercio.vue";
import Concilias from "./views/concilias/Concilia.vue";
import ConciliasForm from "./views/concilias/ConciliaForm.vue";
import PlanUsage from "./views/PlanUsage.vue";
import Encuesta from "./views/Encuestas/Satisfaccion.vue";
import CambioInfoSubs from "./views/concilias/CambioInfoForm.vue";
import Quirofanos from "./views/Quirofanos.vue";
import cotizacionProcesar from "./views/concilias/procesarMetadata.vue";
// import Comisiones from "./views/Comisiones.vue";
// import FormList from "../form/views/FormList";
//import FormBuilder from "../form/views/FormBuilder";

export default [
    /*{
        path: "/history",
        component: History,
    },*/
    {
        path: "/plan-usage",
        component: PlanUsage,
    },
    {
        path: "/account",
        component: Account,
    },
    {
        path: "/account-addresses",
        component: Addresses,
    },
    {
        path: "/account-config",
        component: AccountConfig,
    },
    {
        path: "/account-preferences",
        component: SettingsPreferences,
    },
    {
        path: "/mi-consumo",
        component: MiConsumo,
    },
    /*{
        path: "/plan-and-packages/activation-license/:licence",
        component: MiConsumo,
    },*/
    {
        path: "/package-balance",
        component: MiSaldo,
    },
    {
        path: "/account-payment",
        component: Payment,
    },
    {
        path: "/account-affiliations",
        component: Affiliation,
    },
    {
        path: "/account-personal",
        component: Personal,
    },
    {
        path: "/account-personal/edit/:id",
        component: PersonalForm,
    },
    {
        path: "/personal/forms",
        component: SendForms,
    },
    {
        path: "/account-personal/role",
        component: Roles,
    },
    {
        path: "/account-personal/role/edit/:id",
        component: RoleForm,
    },
    {
        path: "/account-third-party-apps",
        component: AppTerceros,
    },
    {
        path: "/account-third-party-apps/edit/:id",
        component: AppTercerosForm,
    },
    {
        path: "/account-solicitudes",
        component: Solicitudes,
    },
    {
        path: "/account-locations",
        component: Locaciones,
    },
    {
        path: "/account-quirofanos",
        component: Quirofanos,
    },
    {
        path: "/account-shipping",
        component: Shipping,
    },
    {
        path: "/account-solicitudes/view/:id",
        component: SolicitudesForm,
    },
    {
        path: "/account/affiliations/add",
        component: AffiliationForm,
    },
    {
        path: "/account/affiliations/edit/:id",
        component: AffiliationForm,
    },
    {
        path: "/sub-commerce/control-list",
        component: ControlComerciosListado,
    },
    {
        path: "/sub-commerce/control/:id",
        component: ControlComercio,
    },
    {
        path: "/sub-commerce/concilia",
        component: Concilias,
    },
    {
        path: "/sub-commerce/concilia/:id",
        component: ConciliasForm,
    },
    {
        path: "/sub-commerce/edicion",
        component: CambioInfoSubs,
    },
    {
        path: "/sub-commerce/procesar-metadata",
        component: cotizacionProcesar,
    },
    {
        path: "/encuesta/satisfaccion",
        component: Encuesta,
    },
];
