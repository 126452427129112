<template>
    <layout :class="{ active: isActive('/account') }" :title="true">
        <div class="content-body">
            <div class="container">
                <div @click="toggleSidebar" class="btn btn-sm btn-outline-secondary btn-circle">
                    <i :class="isCollapsed ? 'mdi mdi-chevron-right' : 'mdi mdi-chevron-left'"></i>
                </div>
                <div class="row">
                    <div :class="[isCollapsed ? 'd-none' : 'col-xl-3 col-md-4']" style="transition: width 0.3s ease-in-out;">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Configuración</h4>

                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item" v-if="Access('users-config-plan')">
                                        <router-link to="/plan-usage" class="nav-link" :class="{ active: isActive('/plan-usage') }">
                                            <i class="mdi mdi-gauge"></i>
                                            <span>Medidor de consumo</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-form')">
                                        <router-link to="/forms-list" class="nav-link" :class="{ active: isActive('/forms-list') }">
                                            <i class="mdi mdi-file-document"></i>
                                            <span>Docs+</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-form')">
                                        <router-link to="/shortlink-create" class="nav-link" :class="{ active: isActive('/shortlink-create') }">
                                            <i class="mdi mdi-link"></i>
                                            <span>Acortador de URLs</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Configuración</h4>
                            </div>
                            <div class="card-body">
                                <ul>
<!--                                    <li class="nav-item" v-if="Access('users-config-plan')">
                                        <router-link to="/plan-and-packages" class="nav-link" :class="active === 22 && 'active'">
                                            <i class="mdi mdi-credit-card"></i>
                                            <span>Plan y método de pago</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-config-perfil')">
                                        <router-link to="/account" class="nav-link" :class="{ active: isActive('/account') }">
                                            <i class="mdi mdi-account"></i>
                                            <span>Editar perfil</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/medic/dashboard" class="nav-link" :class="active === 28 && 'active'">
                                            <i class="mdi mdi-medical-bag"></i>
                                            <span>Medico</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-integraciones-admin')">
                                        <router-link to="/account-affiliations" class="nav-link" :class="{ active: isActive('/account-affiliations') }">
                                            <i class="la la-boxes"></i>
                                            <span>Integraciones</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-integraciones-solicitud')">
                                        <router-link to="/account-solicitudes" class="nav-link" :class="{ active: isActive('/account-solicitudes') }">
                                            <i class="la la-list-ol"></i>
                                            <span>Solicitudes de integración</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-locations" class="nav-link" :class="{ active: isActive('/account-locations') }">
                                            <i class="mdi mdi-store"></i>
                                            <span>
                                                Localidad, bodegas, envíos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/personal/forms" class="nav-link" :class="{ active: isActive('/personal/forms') }">
                                            <i class="mdi mdi-receipt"></i>
                                            <span>
                                                Envío de facturas a personal
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-quirofanos" class="nav-link" :class="{ active: isActive('/account-quirofanos') }">
                                            <i class="mdi mdi-medical-bag"></i>
                                            <span>
                                                Quirófanos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('personal-usuarios_adicionales')">
                                        <router-link to="/account-personal" class="nav-link" :class="{ active: isActive('/account-personal') }">
                                            <i class="la la-users"></i>
                                            <span>Usuarios adicionales</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/account-shipping" class="nav-link" :class="active === 27 && 'active'">
                                            <i class="mdi mdi-truck-delivery"></i>
                                            <span>Envíos y coberturas</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-sistema')">
                                        <router-link to="/account-config" class="nav-link" :class="{ active: isActive('/account-config') }">
                                            <i class="mdi mdi-cogs"></i>
                                            <span>Sistema</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div :class="isCollapsed ? 'col-xl-12 col-md-12' : 'col-xl-9 col-md-8'">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    data() {
        return {
            isCollapsed: false
        };
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
        })
    },
    methods: {
        isActive(path) {
            return this.$route.path === path;
        },
        toggleSidebar() {
            this.isCollapsed = !this.isCollapsed;
            this.$emit("toggleSidebar", this.isCollapsed);
        }
    },
};
</script>
