 
<template>
    <box  title="Datos de paciente" icon="fa fa-user">
      <section class="row">
        <div class="col-12 row">
          <div class="col-12 col-md-4">
                  <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" v-model="datos_adicionales.usar_datos_cliente"
                      :checked="datos_adicionales.usar_datos_cliente" @change="handlerDataShow"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                          ¿Desea usar los datos del cliente?
                      </span>
                  </label>
              </div>
              <div v-if="datos_adicionales.usar_datos_cliente" class="col-12 col-md-4">
                  <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" v-model="datos_adicionales.usar_datos_adicionales" :checked="datos_adicionales.usar_datos_adicionales" @change=""/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                          ¿Desea ingresar datos adicionales del cliente?
                      </span>
                  </label>
              </div>
        </div>
       <div v-if="datos_adicionales.usar_datos_cliente" class="col-12 row">
          <div  class="form-group col-12 col-md-4">
                      <label>Paciente</label>
                      <input type="text" class="form-control" v-model="datos_cliente.nombre_completo" disabled/>
          </div>
          <div  class="form-group col-12 col-md-4">
                      <label>Fecha de nacimiento</label>
                      <input type="text" class="form-control" v-model="datos_cliente.fecha_nacimiento" disabled/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Edad</label>
                      <input type="text" class="form-control" :value="calcularEdad(datos_cliente.fecha_nacimiento)" disabled />
          </div>
          <div  class="form-group col-12 col-md-4">
                      <label>Correo electronico</label>
                      <input type="text" class="form-control" v-model="datos_cliente.correo" @change="" disabled />
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Telefono</label>
                      <input type="text" class="form-control" v-model="datos_cliente.telefono" @change="" disabled/>
          </div>
       </div>
       <div class="col-12 row">
          <p v-if="datos_adicionales.usar_datos_cliente" class="col-12 mb-1 mt-3">Datos adicionales del paciente</p>
          <template v-if="datos_adicionales.usar_datos_adicionales">
          
          <div class="form-group col-12 col-md-4">
                      <label>Paciente</label>
                      <input type="text" class="form-control" v-model="datos_adicionales.nombre_completo" @change="saveDatosAdicionales"/>
          </div>
          <div v-if="!datos_adicionales.usar_datos_cliente" class="form-group col-12 col-md-4">
                      <label>Fecha de nacimiento</label>
                      <date-picker v-model="datos_adicionales.fecha_nacimiento"  mode="date">
                          <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="form-control" @change="saveDatosAdicionales"/>
                          </template>
                      </date-picker>
          </div>
          <div v-if="!datos_adicionales.usar_datos_cliente" class="form-group col-12 col-md-4">
                      <label>Edad</label>
                      <input type="text" class="form-control" :value="calcularEdad(datos_adicionales.fecha_nacimiento)" disabled />
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Correo electronico</label>
                      <input type="text" class="form-control" v-model="datos_adicionales.correo" @change="saveDatosAdicionales"/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Telefono</label>
                      <input type="text" class="form-control" v-model="datos_adicionales.telefono" @change="saveDatosAdicionales"/>
          </div>
              
          </template>
          <div class="form-group col-12 col-md-4">
                      <label>Referido(a) por</label>
                      <input type="text" class="form-control" v-model="datos_adicionales.referido" @change="saveDatosAdicionales"/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Fecha de referido</label>
                      <date-picker v-model="datos_adicionales.fecha_referido"  mode="date">
                          <template #default="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="form-control" @change="saveDatosAdicionales"/>
                          </template>
                      </date-picker>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Código en sistema</label>
                      <input type="text" class="form-control" v-model="datos_cliente.clientId" @change="" disabled />
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Archivo</label>
                      <input type="text" class="form-control" v-model="datos_adicionales.archivo" @change="saveDatosAdicionales"/>
          </div>
  
       </div>
          
      </section>
  
    </box>
    <box  title="Datos psicológicos" icon="fa-solid fa-brain">
          <section class="row">
          <div class="form-group col-12 col-md-4">
                      <label>Profesíon o Escolaridad</label>
                      <input type="text" class="form-control" v-model="datos_psicologicos.profesion_escolaridad" @change="saveDatosPsicologicos"/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Ocupacion actual</label>
                      <input type="text" class="form-control" v-model="datos_psicologicos.ocupacion" @change="saveDatosPsicologicos"/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Tiempo de desempeño</label>
                      <input type="text" class="form-control" v-model="datos_psicologicos.tiempo_desempeño" @change="saveDatosPsicologicos"/>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>¿Como se siente en su trabajo, estudio u ocupación?</label>
                      <select class="form-control" v-model="datos_psicologicos.como_se_siente" @change="saveDatosPsicologicos">
                          <option value="excelente">Excelente</option>
                          <option value="muy bien">Muy bien</option>
                          <option value="bien">Bien</option>
                          <option value="regular">Regular</option>
                          <option value="mal">Mal</option>
                      </select>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>Describa como siente su sueño</label>
                      <select class="form-control" v-model="datos_psicologicos.calidad_de_sueño" @change="saveDatosPsicologicos">
                          <option value="tranquilo">Tranquilo</option>
                          <option value="intranquilo">Intranquilo</option>
                          <option value="pesadillas">Pesadillas</option>
                          <option value="insomnio">Insomnio</option>
                          <option value="terrores">Terrores</option>
                          <option value="miedos nocturnos">Miedos nocturnos</option>
                          <option value="enuresis">Enuresis</option>
                          <option value="encopresis">Encopresis</option>
                          <option value="otros">Otros</option>
                      </select>
          </div>
          <div class="form-group col-12">
                      <label>¿Con quién vive actualmente?</label>
                      <textarea v-model="datos_psicologicos.con_quien_vive" class="form-control" placeholder="Escribe aquí" @change="saveDatosPsicologicos"></textarea>
          </div>
          <div class="form-group col-12 col-md-4">
                      <label>¿Cómo es la relación con las personas con que vive?</label>
                      <select class="form-control" v-model="datos_psicologicos.relacion_con_personas" @change="saveDatosPsicologicos">
                          <option value="excelente">Excelente</option>
                          <option value="muy buena">Muy buena</option>
                          <option value="buena">Buena</option>
                          <option value="regular">Regular</option>
                          <option value="mala">Mala</option>
                      </select>
          </div>
          <div v-if="datos_cliente.genero === 'F'" class="form-group col-12 col-md-4">
                      <label>¿Esta usted en estado de embarazo?</label>
                      <select class="form-control" v-model="datos_psicologicos.embarazo" @change="saveDatosPsicologicos">
                          <option value="si">Si</option>
                          <option value="no">No</option>
                      </select>
          </div>
          <div v-if="datos_psicologicos.embarazo==='si'" class="form-group col-12">
                      <label>Datos importantes de embarazo, nacimiento y desarrollo</label>
                      <textarea v-model="datos_psicologicos.datos_embarazo" class="form-control" placeholder="Escribe aquí" @change="saveDatosPsicologicos"></textarea>
          </div>
          <div class="form-group col-12">
                      <label>Datos familiares importantes u observaciones con respecto a su historia clínic</label>
                      <textarea v-model="datos_psicologicos.observaciones_clinicas" class="form-control" placeholder="Escribe aquí" @change="saveDatosPsicologicos"></textarea>
          </div>
          <div class="form-group col-12">
                      <label>Emociones que manifiesta en este momento</label>
                      <textarea v-model="datos_psicologicos.emociones" class="form-control" placeholder="Escribe aquí" @change="saveDatosPsicologicos"></textarea>
          </div>
          <div class="form-group col-12">
                      <label>Fórmula Floral</label>
                      <textarea v-model="datos_psicologicos.formula_floral" class="form-control" placeholder="Escribe aquí" @change="saveDatosPsicologicos"></textarea>
          </div>
          </section>
    </box>
</template>
   
   
  <style scoped>
   
   textarea{
      min-height: 150px;
      max-height: 500px;
   }
  </style>

<script>
import {DatePicker} from 'v-calendar';
import {mapGetters} from "vuex";
import API from "src/core/Api";
import Box from "src/modules/medico/views/fichas/Box.vue";
import Tools from 'src/core/Tools';
 
export default { 
 data() {
        return {
        //data
        datos_cliente:{
            clientId: '',
            nombre_completo: '',
            fecha_nacimiento: '',
            correo: '',
            telefono: '',
            genero: '',
        },
        datos_adicionales:{
            nombre_completo: '',
            fecha_nacimiento: '',
            correo: '',
            telefono: '',
            archivo: '',
            referido: '',
            fecha_referido: '',
            usar_datos_cliente: false,
            usar_datos_adicionales: true,
        },
        datos_psicologicos:{
            profesion_escolaridad: '',
            ocupacion: '',
            tiempo_desempeño: '',
            como_se_siente: '',
            calidad_de_sueño: '',
            con_quien_vive: '',
            relacion_con_personas: '',
            embarazo: '',
            datos_embarazo: '',
            observaciones_clinicas: '',
            emociones: '',
            formula_floral: '',
        }
        
 }},
 watch: {
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                const self = this;

                if(self.GetMedicoFichaActive.cliente){
                    this.datos_cliente.clientId=self.GetMedicoFichaActive.cliente.id;
                    this.datos_cliente.nombre_completo=self.GetMedicoFichaActive.cliente.nombres+' '+self.GetMedicoFichaActive.cliente.apellidos;
                    this.datos_cliente.fecha_nacimiento=self.GetMedicoFichaActive.cliente.fechaNacimiento;
                    this.datos_cliente.correo=self.GetMedicoFichaActive.cliente.email;
                    this.datos_cliente.telefono=self.GetMedicoFichaActive.cliente.telefono;
                    this.datos_cliente.genero=self.GetMedicoFichaActive.cliente.genero;

                }

                Object.keys(self.datos_adicionales).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.datos_adicionales!= undefined){
                        if (self.GetMedicoFichaActive.metadata.datos_adicionales[value]!= undefined) self.datos_adicionales[value] = self.GetMedicoFichaActive.metadata.datos_adicionales[value];
                    }
                    });

                    Object.keys(self.datos_psicologicos).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.datos_psicologicos!= undefined){
                        if (self.GetMedicoFichaActive.metadata.datos_psicologicos[value]!= undefined) self.datos_psicologicos[value] = self.GetMedicoFichaActive.metadata.datos_psicologicos[value];
                    }
                    });

             }    
        },
        'datos_adicionales.fecha_nacimiento': function () {
            this.saveDatosAdicionales();
        },
        'datos_adicionales.fecha_referido': function () {
            this.saveDatosAdicionales();
        }
},
 mounted(){
    this.emitter.emit("medico-ficha-load",['datos_psicologicos','datos_adicionales',''])  
},
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        })
    },
 components:{
  Box,
  DatePicker,
 },
 methods: {
        calcularEdad(fechaNacimiento) {
            return Tools.calculateAge(fechaNacimiento);
        },
        saveDatosAdicionales(){
            const self = this;

                API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'datos_adicionales',
                    detail: self.datos_adicionales,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-notify",['datos_adicionales']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

        },
        saveDatosPsicologicos(){
            const self = this;

            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'datos_psicologicos',
                detail: self.datos_psicologicos,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                self.emitter.emit("medico-ficha-notify",['datos_psicologicos']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        handlerDataShow(){
            if (this.datos_adicionales.usar_datos_cliente) {
                this.datos_adicionales.usar_datos_adicionales=!this.datos_adicionales.usar_datos_adicionales;
            }else{
                this.datos_adicionales.usar_datos_adicionales=true;
            }
        }
}
}
</script>