<template>
  <layout>
    <div class="content-body fichaMedicaForm">
      <ficha-nutricion v-if="currentFichaType === 'nutricion'" :tabDefault="tabDefault"></ficha-nutricion>
      <ficha-ginecologia v-if="currentFichaType === 'ginecologia'" :tabDefault="tabDefault"></ficha-ginecologia>
      <ficha-medicina-general v-if="currentFichaType === 'medicina_general'" :tabDefault="tabDefault"></ficha-medicina-general>
      <ficha-pediatria v-if="currentFichaType === 'pediatria'" :tabDefault="tabDefault"></ficha-pediatria>
      <ficha-urgencia v-if="currentFichaType === 'urgencia'" :tabDefault="tabDefault"></ficha-urgencia>
      <ficha-odontologia v-if="currentFichaType === 'odontologia'" :tabDefault="tabDefault"></ficha-odontologia>
      <ficha-veterinaria v-if="currentFichaType === 'veterinaria'" :tabDefault="tabDefault"></ficha-veterinaria>
      <ficha-podologia v-if="currentFichaType === 'podologia'" :tabDefault="tabDefault"></ficha-podologia>
      <ficha-traumatologia v-if="currentFichaType === 'traumatologia'" :tabDefault="tabDefault"></ficha-traumatologia>
      <ficha-urologia v-if="currentFichaType === 'urologia'" :tabDefault="tabDefault"></ficha-urologia>
      <ficha-fisioterapia v-if="currentFichaType === 'fisioterapia'" :tabDefault="tabDefault"></ficha-fisioterapia>
      <ficha-dermatologia v-if="currentFichaType === 'dermatologia'" :tabDefault="tabDefault"></ficha-dermatologia>
      <ficha-psicologia v-if="currentFichaType === 'psicologia'" :tabDefault="tabDefault"></ficha-psicologia>
      <ficha-geriatria v-if="currentFichaType === 'geriatria'" :tabDefault="tabDefault"></ficha-geriatria>
      <ficha-tanatologia v-if="currentFichaType === 'tanatologia'" :tabDefault="tabDefault"></ficha-tanatologia>
      <ficha-prenatal v-if="currentFichaType === 'prenatal'" :tabDefault="tabDefault"></ficha-prenatal>
      <div v-if="!currentFichaType" class="loading-ficha my-5 p-4 text-danger text-center">
        <div class="spinner-box">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import { store } from "src/store";
import { mapActions, mapGetters } from "vuex";

// FICHAS MÉDICAS DISPONIBLES
import FichaGinecologia from './fichas/medico/Ginecologia/Ginecologia.vue';
import FichaNutricion from './fichas/medico/Nutricion/Nutricion.vue';
import FichaMedicinaGeneral from './fichas/medico/MedicinaGeneral/MedicinaGeneral.vue';
import FichaPediatria from './fichas/medico/Pediatria/Pediatria.vue';
import FichaUrgencia from './fichas/medico/Urgencia/Urgencia.vue';
import FichaOdontologia from './fichas/medico/Odontologia/Odontologia.vue';
import FichaPodologia from './fichas/medico/Podologia/Podologia.vue';
import FichaVeterinaria from './fichas/medico/Veterinaria/Veterinaria.vue';
import FichaTraumatologia from './fichas/medico/Traumatologia/Traumatologia.vue';
import FichaUrologia from './fichas/medico/Urologia/Urologia.vue';
import FichaFisioterapia from "./fichas/medico/Fisioterapia/Fisioterapia.vue";
import FichaDermatologia from "./fichas/medico/Dermatologia/Dermatologia.vue";
import FichaPsicologia from "./fichas/medico/Psicologia/Psicologia.vue";
import FichaGeriatria from "./fichas/medico/Geriatria/Geriatria.vue";
import FichaTanatologia from "./fichas/medico/Tanatologia/Tanatologia.vue";
import FichaPrenatal from "./fichas/medico/Prenatal/Prenatal.vue";

export default {
  components: {
    Layout,
    FichaUrologia,
    FichaGinecologia,
    FichaNutricion,
    FichaMedicinaGeneral,
    FichaPediatria,
    FichaUrgencia,
    FichaOdontologia,
    FichaVeterinaria,
    FichaTraumatologia,
    FichaPodologia,
    FichaFisioterapia,
    FichaDermatologia,
    FichaPsicologia,
    FichaGeriatria,
    FichaTanatologia,
    FichaPrenatal
  },
  data() {
    return {
      // ...existing code...
      tabDefault: 'preclinica',
      ws: null,
      idReserva: 0,
      ws_url: process.env.ANY_WS
    };
  },
  computed: {
    ...mapGetters({
      AuthGetUserInfo: 'AuthGetUserInfo',
      AuthGetToken: 'AuthGetToken',
      GetMedicoFichaActive: 'GetMedicoFichaActive',
      GetReloadFicha: 'GetReloadFicha'
    }),
    currentFichaType() {
      return this.$store.state.medico.currentFichaType;
    }
  },
  mounted() {
    const self = this;
    // ...existing code...
    self.ClearAllFichaData();
    self.idReserva = this.$route.params.reservaId;
    let cacheTab = self.getWithExpiry('cacheTab');
    if (cacheTab != null) {
      self.tabDefault = cacheTab;
    }

    this.FetchFichaData({ reservaId: this.idReserva, slugs: [], initialData: 1 });
   
    this.medicoFichaLoadHandler = (slugs) => {
      this.FetchFichaData({ reservaId: this.idReserva, slugs, initialData: 0 });
    };
    this.medicoFichaRefreshHandler = (slugs) => {
      this.FetchFichaData({ reservaId: this.idReserva, slugs, initialData: 0 });
      this.enviarActualizacion(slugs);
    };
    this.medicoFichaClientRefreshHandler = (slugs) => {
      this.FetchFichaData({ reservaId: this.idReserva, slugs, initialData: 1 });
      this.enviarActualizacion(slugs);
    };
    this.medicoFichaNotifyHandler = (slugs) => {
      this.enviarActualizacion(slugs);
    };
    this.saveDefaultTabHandler = (tab) => {
      this.tabDefault = tab;
    };

    // Registra los listeners
    this.emitter.on("medico-ficha-load", this.medicoFichaLoadHandler);
    this.emitter.on("medico-ficha-refresh", this.medicoFichaRefreshHandler);
    this.emitter.on("medico-ficha-client-refresh", this.medicoFichaClientRefreshHandler);
    this.emitter.on("medico-ficha-notify", this.medicoFichaNotifyHandler);
    this.emitter.on("save-default-tab", this.saveDefaultTabHandler);

    this.conectarWebSocket();
  },
  methods: {
    ...mapActions({
      SetMedicoFichaActive: 'SetMedicoFichaActive',
      SetReloadFicha: 'SetReloadFicha',
      SetClienteMetada: 'SetClienteMetada',
      SetHistoryFiltred: 'SetHistoryFiltred',
      ClearAllFichaData: 'ClearAllFichaData',
      FetchFichaData: 'FetchFichaData'
    }),
    dayjs,
    Numbro,
    conectarWebSocket() {
      let token = this.AuthGetToken;
      let user_id = this.AuthGetUserInfo.id;
      let reserva_id = parseInt(this.idReserva);
      this.ws = new WebSocket(`${this.ws_url}ficha_update`);
      this.ws.onopen = () => {
        console.log("%cConexión WebSocket establecida 👌", "background: green; color: white; padding: 4px;");
        const authMessage = { action: "authenticate", token, user_id, reserva_id };
        this.ws.send(JSON.stringify(authMessage));
      };
      this.ws.onmessage = (event) => {
        const self = this;
        const data = JSON.parse(event.data);
        if (data.status === "authenticated") {
          console.log("%cCliente autenticado correctamente ✅", "background: green; color: white; padding: 4px;");
        } else if (data.status === "success" && data.action === "update") {
          if (data.slug === "") {
            this.$store.dispatch('SetReloadFicha', true);
            window.location.reload();
            this.$store.dispatch('SetReloadFicha', false);
            return;
          }
          if (this.tabDefault === data.tab) {
            self.saveCacheTab();
            self.loadReserva([data.slug]);
          }
          console.log("%cFicha actualizada!", "background: green; color: white; padding: 4px;");
        } else if (data.status === "error") {
          console.error("%c" + data.message, "background: red; color: white; padding: 4px;");
        }
      };
      this.ws.onclose = (event) => {
        if (event.code === 1000) {
          console.log("%cConexión WebSocket cerrada totalmente", "background: red; color: white; padding: 4px;");
        } else {
          console.log("%cConexión WebSocket cerrada por exceso de tiempo de espera.", "background: orange; color: white; padding: 4px;");
          console.log("%cIntentando reconectar... 🔌", "background: orange; color: white; padding: 4px;");
          setTimeout(() => { this.conectarWebSocket(); }, 5000);
        }
      };
      this.ws.onerror = (error) => {
        console.error("%cError en el WebSocket: " + error, "background: red; color: white; padding: 4px;");
      };
    },
    enviarActualizacion(slug = []) {
      let slugToUpdate = "";
      if (slug.length > 0) {
        slugToUpdate = slug[0];
      }
      let user_id = this.AuthGetUserInfo.id;
      let reserva_id = parseInt(this.idReserva);
      let tab = this.tabDefault;
      const fichaActualizada = { action: "update", user_id, reserva_id, slug: slugToUpdate, tab };
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(fichaActualizada));
      } else {
        console.error("%cConexión WebSocket no está abierta", "background: orange; color: white; padding: 4px;");
      }
    },
    loadReserva(slugs = []) {
      const self = this;
      self.$store.dispatch('SetReloadFicha', true);
      if (slugs.length > 0) {
        let fichaDataTemp = self.GetMedicoFichaActive;
        API.send('POST', 'orders/metadata/by-slug', {
          ordenId: parseInt(fichaDataTemp.orden.id),
          slugs
        }, function (response) {
          if (Object.keys(response.data).length > 0) {
            slugs.forEach(s => { fichaDataTemp.metadata[s] = response.data[s]; });
            self.$store.dispatch('SetMedicoFichaActive', fichaDataTemp);
          } else if (slugs.length === 1) {
            fichaDataTemp.metadata[slugs[0]] = undefined;
            self.$store.dispatch('SetMedicoFichaActive', fichaDataTemp);
          }
          self.$store.dispatch('SetReloadFicha', false);
        }, function (response) {
          API.showErrorNotify(response.msg);
        });
      } else {
        API.send('POST', 'orders/metadata/initial-data', {
          reservaId: self.idReserva,
          slugs
        }, function (response) {
          self.tipoFicha = response.data.producto.fichaMedica;
          self.$store.dispatch('SetMedicoFichaActive', response.data);
          self.$store.dispatch('SetReloadFicha', false);
        }, function (response) {
          API.showErrorNotify(response.msg);
        });
      }
    },
    loadClientReserva(slugs = []) {
      const self = this;
      self.$store.dispatch('SetReloadFicha', true);
      API.send('POST', 'orders/metadata/initial-data', {
        reservaId: self.idReserva,
        slugs
      }, function (response) {
        self.tipoFicha = response.data.producto.fichaMedica;
        self.$store.dispatch('SetMedicoFichaActive', response.data);
        self.$store.dispatch('SetReloadFicha', false);
      }, function (response) {
        API.showErrorNotify(response.msg);
      });
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = { value: value, expiry: now.getTime() + ttl };
      sessionStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = sessionStorage.getItem(key);
      if (!itemStr) return null;
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        sessionStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    saveCacheTab() {
      this.setWithExpiry('cacheTab', this.tabDefault, 1 * 60 * 1000);
    }
  },
  beforeUnmount() {

    this.emitter.off("medico-ficha-load", this.medicoFichaLoadHandler);
    this.emitter.off("medico-ficha-refresh", this.medicoFichaRefreshHandler);
    this.emitter.off("medico-ficha-client-refresh", this.medicoFichaClientRefreshHandler);
    this.emitter.off("medico-ficha-notify", this.medicoFichaNotifyHandler);
    this.emitter.off("save-default-tab", this.saveDefaultTabHandler);

    if (this.ws && this.ws.close && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close(1000);
    }
    this.ClearAllFichaData();
  }
};
</script>

<style scoped>
.loading-ficha {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>