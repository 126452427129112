import { CONFIG } from 'src/config';

const localDisk = {
	read(key, customDisk) {
		if (customDisk === undefined) {
			const item = localStorage.getItem(key);
			return item ? JSON.parse(item) : null;
		} else {
			customDisk = customDisk || false;
			const diskKey = customDisk || CONFIG.localStorageDisk;
			const tmp = localStorage.getItem(diskKey);
			let objTMP = {};
			if (tmp) {
				try {
					objTMP = JSON.parse(tmp);
					return typeof objTMP[key] !== 'undefined' ? objTMP[key] : null;
				} catch (e) {
					return null;
				}
			}
			return null;
		}
	},
	write(key, data, customDisk) {
		if (customDisk === undefined) {
			localStorage.setItem(key, JSON.stringify(data));
		} else {
			customDisk = customDisk || false;
			const diskKey = customDisk || CONFIG.localStorageDisk;
			const tmp = localStorage.getItem(diskKey);
			let objTMP = {};
			if (tmp) {
				try {
					objTMP = JSON.parse(tmp);
				} catch (e) {
					objTMP = {};
				}
			}
			objTMP[key] = data;
			localStorage.setItem(diskKey, JSON.stringify(objTMP));
		}
	},

	clearDisk(customDisk) {
		customDisk = customDisk || false;
		localStorage.setItem(customDisk || CONFIG.localStorageDisk, null);
	},
	remove(key) {
		localStorage.removeItem(key);
	}
};

export default localDisk;