<template>
    <SettingLayout :active="40">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Administrar Quirófanos</h4>
                        <button class="btn btn-primary" @click="resetItemEdit(); newItem = 1">Agregar Nuevo Quirófano</button>
                    </div>
                    <div class="card-body">
                        <div class="mb-4">
                            Aquí puedes administrar las salas de quirófano, incluyendo su disponibilidad, recursos, y personal asignado.
                        </div>

                        <div v-if="itemEdit.id > 0 || newItem">
                            <hr>
                            <div class="form-row mb-2">
                                <div class="col-12">
                                    <h5 class="text-primary">Información General</h5>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Nombre del Quirófano *</label>
                                    <input type="text" class="form-control" placeholder="Ejemplo: Quirófano 1" v-model="itemEdit.nombre" name="nombre"/>
                                    <span class="validationError">{{ Valid.nombre }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Capacidad (personas) *</label>
                                    <input type="number" class="form-control" placeholder="Ejemplo: 5" v-model="itemEdit.capacidad" name="capacidad"/>
                                    <span class="validationError">{{ Valid.capacidad }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Recursos Disponibles *</label>
                                    <textarea class="form-control" placeholder="Ejemplo: Mesa de operaciones, luces quirúrgicas, monitor" v-model="itemEdit.recursos"></textarea>
                                    <span class="validationError">{{ Valid.recursos }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Personal Asignado</label>
                                    <input type="text" class="form-control" placeholder="Ejemplo: Dr. Pérez, Enfermera López" v-model="itemEdit.personal" name="personal"/>
                                    <span class="validationError">{{ Valid.personal }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Horario de Operación *</label>
                                    <input type="text" class="form-control" placeholder="Ejemplo: 8:00 AM - 6:00 PM" v-model="itemEdit.horario" name="horario"/>
                                    <span class="validationError">{{ Valid.horario }}</span>
                                </div>
                                <div class="form-group col-xl-6 text-center pt-4">
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" v-model="itemEdit.estado" :checked="itemEdit.estado"/>
                                        <div class="toggle-switch"></div>
                                        <span class="toggle-label"> Disponible </span>
                                    </label>
                                </div>
                                <div class="form-group col-12 text-right">
                                    <hr>
                                    <button @click="saveItem" class="btn btn-primary mr-2">Guardar</button>
                                    <button @click="resetItemEdit" class="btn btn-danger">Cancelar</button>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <ul v-if="Object.keys(locaciones).length > 0" class="linked_account">
                                <li v-for="item in locaciones" :key="item.id">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="media">
                                                <span class="mr-3"><i class="fa fa-hospital"></i></span>
                                                <div class="media-body">
                                                    <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                                    <p>
                                                        Capacidad: <b>{{ item.capacidad }} personas</b>
                                                    </p>
                                                    <p>
                                                        Recursos: {{ item.recursos }}
                                                    </p>
                                                    <p>
                                                        Horario: {{ item.horario }}
                                                    </p>
                                                </div>
                                                <div class="edit-option">
                                                    <a @click="editItem(item)"><i class="fa fa-pencil"></i></a>
                                                    <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div v-else class="text-center">
                                Aún no hay quirófanos configurados.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </SettingLayout>
</template>

<script>
import SettingLayout from "../components/settings/settingLayout";
import { mapGetters } from "vuex";
import API from "src/core/Api";
import DataValidation from "src/core/DataValidation";

export default {
    components: {
        SettingLayout
    },
    data() {
        return {
            Valid: {},
            locaciones: {},
            newItem: false,
            itemEdit: {
                id: 0,
                nombre: "",
                capacidad: 0,
                recursos: "",
                personal: "",
                horario: "",
                estado: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    methods: {
        resetItemEdit() {
            this.newItem = 0;
            this.itemEdit = {
                id: 0,
                nombre: "",
                capacidad: 0,
                recursos: "",
                personal: "",
                horario: "",
                estado: 0
            };
        },
        loadItems() {
            const self = this;
            API.send('GET', 'quirofanos/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        editItem(item) {
            this.itemEdit = {...item};
        },
        deleteItem(id) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no puede revertirse', function () {
                API.send('DELETE', 'quirofanos/delete', {id},
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            });
        },
        saveItem() {
            const self = this;
            const validation = new DataValidation();
            validation.required('nombre', this.itemEdit.nombre, 'Ingrese un nombre válido');
            validation.required('capacidad', this.itemEdit.capacidad, 'Ingrese la capacidad');
            validation.required('recursos', this.itemEdit.recursos, 'Ingrese los recursos disponibles');
            validation.required('horario', this.itemEdit.horario, 'Ingrese el horario');

            this.Valid = validation.validate(function () {
                API.send('POST', 'quirofanos/save', self.itemEdit,
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            });
        }
    },
    mounted() {
        this.loadItems();
    }
};
</script>

<style scoped>
.card {
    margin-bottom: 20px;
}
</style>
