<template>
        <box title="Listado de adjuntos " icon="fa fa-paperclip" :contraction='true'>
            <div v-if="typeof GetMedicoFichaActive.metadata.attach === 'undefined'">
                <div class="text-center text-danger">No tiene adjuntos cargados</div>
            </div>
            <div v-else>
                <div v-for="(attachCat, key) in GetMedicoFichaActive.metadata.attach" :key="key">
                    <template v-if="(key==='L') || (key==='I') || (key==='O')">
                        <box :title="selectBoxTitle(key)" :icon="selectBoxIcon(key)" :contraction="true" >   
                            <div class="row">
                                <div class="col-12 col-sm-4 mb-2" v-for="item in attachCat" :key="item.id">
                                    <div class="attachmentLine">
                                        <div class="mb-3">
                                            <div title="Ver pdf" @click="openPDFModal(item.urlTMP.fileUrl)" class="d-flex justify-content-between align-items-center title_container">
                                                <h6 class="font-weight-bold m-0">{{item.detail.name || 'Sin nombre'}}</h6>
                                                <i class="fa-regular fa-eye expand-icon"></i>
                                            </div>
                                            <hr class="my-1">
                                            <div>
                                                <b>Fecha de carga:</b> {{ dayjs(item.createdAt).format('DD-MM-YYYY') || '' }}
                                            </div>
                                            <div>
                                                <b>Hora de carga:</b> {{ dayjs(item.createdAt).format('HH:mm') || '' }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <div v-if="item.urlTMP.fileUrl" :key="item.urlTMP.fileUrl">
                                                    <object :data="item.urlTMP.fileUrl" type="application/pdf" width="100%" height="350">
                                                        <p>El visor de PDF no está disponible en tu navegador. Puedes <a :href="item.urlTMP.fileUrl">descargar el PDF</a> en su lugar.</p>
                                                    </object>
                                                </div>
                                                <div v-else>
                                                    Sin PDF adjunto.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">                   
                                            <div class="col-12 d-flex justify-content-end align-items-center p-2">
                                                <div class="m-1 btn btn-success btn-sm" @click="sendAdjunto(item, key)" style="font-size: 0.85rem;">
                                                    Enviar <i class="fas fa-envelope cursor-pointer" style="font-size: 0.85rem;"></i>
                                                </div>
                                                <div class="m-1 btn btn-primary btn-sm" @click="downloadAdjunto(item)" style="font-size: 0.85rem;">
                                                    Descargar <i class="fas fa-download cursor-pointer" style="font-size: 0.85rem;"></i>
                                                </div>
                                                <div class="m-1 btn btn-danger btn-sm" @click="deleteAdjunto(item)" style="font-size: 0.85rem;">
                                                    Eliminar <i class="fas fa-trash cursor-pointer" style="font-size: 0.85rem;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="attachCat.length===0" class="w-100 p-3 d-flex justify-content-center align-items-center">
                                    <p>Sin Adjuntos</p>
                                </div>
                            </div>
                        </box>
                    </template>
                </div>
            </div>
            <hr>
            <div>
                <h5 class="text-primary">Carga de adjuntos <i class="fa fa-upload ml-3"></i></h5>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-sm-4">
                    <div>
                        <h5>Cargar Laboratorios</h5>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <FileUploader
                                    url="orders/metadata-attachments/save"
                                    :accepted-mime="[
                                        'image/*',
                                        'application/pdf',
                                        'application/x-xls',
                                        'application/vnd.ms-excel',
                                        'application/msword',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/dicom', // .dcm
                                        'application/hdr',    // .hdr
                                        'application/nii',    // .nii
                                        'application/ktx',
                                    ]"
                                    remove-file-on-finish="1"
                                    :extra-data-form="laboratoriosUploadExtraDataForm"
                                    @fileSuccess="fileSuccess"
                                    ></FileUploader>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4">
                    <div>
                        <h5>Cargar Imágenes</h5>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <FileUploader url="orders/metadata-attachments/save"
                                    :accepted-mime="['image/*', 'application/pdf']"
                                        remove-file-on-finish="1"
                                        :extra-data-form="imagenesUploadExtraDataForm"
                                        @fileSuccess="fileSuccess"></FileUploader>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4">
                    <div>
                        <h5>Cargar otros adjuntos</h5>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <FileUploader
                                    url="orders/metadata-attachments/save"
                                    :accepted-mime="[
                                        'image/*',
                                        'file/.dcm',
                                        'application/pdf',
                                        'application/x-xls',
                                        'application/vnd.ms-excel',
                                        'application/msword',
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        'application/octet-stream',
                                    ]"
                                    remove-file-on-finish="1"
                                    :extra-data-form="otrosUploadExtraDataForm"
                                    @fileSuccess="fileSuccess"
                                ></FileUploader>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="modalPDFViewer" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="w-100 d-flex justify-content-between align-items-center">
                                <h5 class="modal-title" id="modal-cita-titulo">PDF view</h5>
                                <button type="button" class="btn btn-danger" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <iframe id="pdfViewer" :src="medicDocUrl" style="width: 100%; height: 700px;" frameborder="0"></iframe> 
                        </div>
                    </div>
                </div>
            </div>
        </box> 
        <box title="Histórico de Adjuntos" icon="fa fa-history" :contraction='true'>
            <div class="modo-vista mt-2 p-2 d-flex justify-content-end align-items-center">
                <div class="icon-container p-2">
                    <i class="fa-solid fa-table-list p-0 m-0" @click="viewMode = 'table'"></i>
                </div>
                <div class="icon-container ml-2 p-2">
                    <i class="fa-solid fa-grip p-0 m-0" @click="viewMode = 'grid'"></i>
                </div>
            </div>
            <section v-if="GetHistoryFiltred && viewMode === 'grid'" class="row mt-1 p-2">
                <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                    <div class="col-12 mt-2" v-if="Object.keys(item.metadata).length > 0">
                        <h6 class="font-weight-bold w-100">Adjuntos de Orden N° {{ item.ordenId }} con fecha {{ dayjs(item.fechaReserva).format('DD-MM-YYYY') }}</h6>
                        <hr class="my-1 w-100">
                        <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                            <div class="row mt-2" v-if="Object.keys(attachCat).length > 0">
                                <template v-if="(key==='L') || (key==='I') || (key==='O')">
                                    <h6 class="font-weight-bold col-12">{{ selectBoxTitle(key) }}</h6>
                                    <div class="col-12 row p-2">
                                        <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                            <div class="col-12 col-sm-4 mb-3">
                                                <div class="attachmentLine">
                                                    <div class="mb-3">
                                                        <div title="Ver pdf" @click="openPDFModal(attachItem.urlTMP.fileUrl)" class="d-flex justify-content-between align-items-center title_container">
                                                            <h6 class="font-weight-bold m-0">{{ attachItem.detail.name || 'Sin nombre' }}</h6>
                                                            <i class="fa-regular fa-eye expand-icon"></i>
                                                        </div>
                                                        <hr class="my-1">
                                                        <div>
                                                            <b>Fecha de carga:</b> {{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') || '' }}
                                                        </div>
                                                        <div>
                                                            <b>Hora de carga:</b> {{ dayjs(attachItem.createdAt).format('HH:mm') || '' }}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 text-center">
                                                            <div v-if="attachItem.urlTMP.fileUrl">
                                                                <object :data="attachItem.urlTMP.fileUrl" type="application/pdf" width="100%" height="350">
                                                                    <p>El visor de PDF no está disponible en tu navegador. Puedes <a :href="attachItem.urlTMP.fileUrl">descargar el PDF</a> en su lugar.</p>
                                                                </object>
                                                            </div>
                                                            <div v-else>
                                                                Sin PDF adjunto.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </section>
            <section v-if="GetHistoryFiltred && viewMode === 'table'" class="mt-1 p-2">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Orden N°</th>
                            <th>Nombre</th>
                            <th>Fecha de carga</th>
                            <th>Hora de carga</th>
                            <th>Categoría</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                            <template v-if="Object.keys(item.metadata).length > 0">
                                <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                                    <template v-if="(key==='L') || (key==='I') || (key==='O')">
                                        <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                            <tr>
                                                <td>{{ item.ordenId }}</td>
                                                <td>{{ attachItem.detail.name || 'Sin nombre' }}</td>
                                                <td>{{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') }}</td>
                                                <td>{{ dayjs(attachItem.createdAt).format('HH:mm') }}</td>
                                                <td>{{ selectBoxTitle(key) }}</td>
                                                <td>
                                                    <i class="fa-regular fa-eye text-primary mr-2" @click="openPDFModal(attachItem.urlTMP.fileUrl)"></i>
                                                    <i class="fas fa-download text-success mr-2" @click="downloadAdjunto(attachItem)"></i>
                                                    <i class="fas fa-trash text-danger" @click="deleteAdjunto(attachItem)"></i>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </section>
            <section v-else class="mt-3 d-flex justify-content-center">
                <p class="text-muted">No se encontro historico de adjuntos</p>
            </section>
        </box>
</template>
<script>
import API from "src/core/Api";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import FileUploader from "src/components/files/FileUploader.vue";
import Tools from "src/core/Tools";

// Componentes

export default {
    components: {
        FileUploader,
        Box,
        Slider
    },
    props: [
        'type',
    ],
    data() {
        return {
            adjunto: {},
            laboratoriosUploadExtraDataForm: {
                orderId: 0,
                category: 'L',
            },
            imagenesUploadExtraDataForm: {
                orderId: 0,
                category: 'I',
            },
            otrosUploadExtraDataForm: {
                orderId: 0,
                category: 'O',
            },
            medicDocUrl: '',
            modalPDFViewer: false,
            viewMode: 'table',
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
            GetHistoryFiltred: 'GetHistoryFiltred',
        })
    },
    watch: {
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

            }
        }
    },
    mounted() {

        this.emitter.emit("medico-ficha-load",['attach'])

        this.laboratoriosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
        this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
        this.otrosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id
        this.FetchHistoryFiltred(['attach']); // Fetch historical metadata for attachments
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
            FetchHistoryFiltred: 'FetchHistoryFiltred', // Add this line to map the action
        }),
        dayjs,
        Numbro,
        selectBoxTitle(key){
            if(key==='L'){
                return 'Laboratorios';
            }else if(key==='I'){
                return 'Imagenes';
            }else if(key==='O'){
                return 'Otros';
            }
        },
        selectBoxIcon(key){
            if(key==='L'){
                return 'fa fa-vial';
            }else if(key==='I'){
                return 'fa-solid fa-image';
            }else if(key==='O'){
                return 'fa fa-file';
            }
        },
        // subida de archivos
        fileSuccess(data) {
            this.gallery = Tools.pushToObject(this.gallery, data);
            this.emitter.emit("medico-ficha-refresh",['attach']);
        },
        // La función downloadAdjunto se activa solo al hacer clic en el botón "Descargar"
        downloadAdjunto(item) {
            API.send('POST', 'orders/metadata-attachments/download', {
                metadataId: item.id,
            }, function (response) {
                if (response.data) {
                    window.open(response.data);
                }
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        sendAdjunto(item, tipoAdjunto){
          API.send('POST', 'orders/metadata-attachments/send', {
                metadataId: item.id,
                orderId: this.GetMedicoFichaActive.orden.id,
                title: tipoAdjunto
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        deleteAdjunto(item) {
            const self = this;
            // Guardo y luego emito el evento
            API.showConfirm('Espere', 'Si elimina este adjunto, no podrá recuperarlo. ¿Desea continuar?', function () {
                API.send('POST', 'orders/metadata-attachments/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh",['attach']);
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        handlerModal(){
            this.modalPDFViewer = !this.modalPDFViewer;
        },
        openPDFModal(url) {
            this.medicDocUrl = url;
            this.handlerModal();
        },
    },
};

</script>
<style scoped>
.expand-icon {
    transition: transform 0.2s;
    transform-origin: center;
}
.title_container:hover .expand-icon{
    transform: scale(1.2);
    color:#0ca7e0;
}
.modo-vista i {
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.2rem;
}
.icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
    border-radius: 4px;
}
</style>
