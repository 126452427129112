import Screen from "src/modules/colas/views/Screen.vue";
//import ShortlinkCreate from "src/modules/shortLink/views/ShortlinkCreate.vue";

export default [
    {
        path: "/colas/screen",
        component: Screen,
    },
    /*{
        path: "/shortlink-create",
        component: ShortlinkCreate,
    },*/
    /*{
        path: "/form-builder",
        component: FormBuilder,
    },
    {
        path: "/form-builder/:id",
        component: FormBuilder,
    },
    {
        path: "/docs-plus-ocr",
        component: OCRList,
    },
    {
        path: "/docs-plus-ocr/:id",
        component: OCRForm,
    },*/
];
