<template>
        <box title="Tratamiento" icon="fa fa-comment-medical" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="form-group">
                        <label>Ingrese el tratamiento</label>
                        <textarea v-model="observaciones" class="form-control" placeholder="Escribe aquí" @change="saveOtros" style="min-height: 250px"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Historico de Tratamientos" icon="fa fa-comment-medical" :contraction='true'>
            <section v-if="(GetHistoryFiltred)" class="row tl mt-3">

                <ul class="col-12">
                    <li v-for="(item, key) in GetHistoryFiltred" class="tlbox" :style="{'--accent-color': item.ordenId === GetMedicoFichaActive.orden.id ? '#00D084' : '#0d6efd'}">
                        <div class="timeheader">Consulta de {{capitalizeFirstLetter(item.fichaMedica)}} con Orden N° {{item.ordenId}} con fecha {{ dayjs(item.fechaReserva).format('DD-MMMM-YYYY', 'es') || '' }}</div>
                        <template v-if="item.metadata.tratamiento || item.metadata.tratamiento_receta || item.metadata.tratamiento_aplicacion">
                            <div v-if="item.metadata.tratamiento" class="row">
                                <div class="col-12">
                                    <div class="timeDate">Tratamiento</div>
                                    <div class="timeDescrip">
                                        {{ item.metadata.tratamiento.observaciones }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.metadata.tratamiento_receta" class="row">
                                <div class="col-12">
                                    <div class="timeDate">Receta</div>
                                    <div class="timeDescrip">
                                        <table v-if="item.metadata.tratamiento_receta" class="table align-middle m-0">
                                        <thead class="small flex-sm-nowrap font-weight-bold">
                                        <tr class="font-weight-bold small">
                                            <th class="font-weight-bold">Medicamento</th>
                                            <th class="font-weight-bold">Administración</th>
                                            <th class="font-weight-bold">Dosis y frecuencia</th>
                                            <th class="font-weight-bold">Observaciones</th>
                                        </tr>
                                        </thead>
                                        <tbody class="small">
                                        <tr v-for="item in item.metadata.tratamiento_receta" :key="item.id">
                                            <template v-if="(typeof item.detail !== 'undefined')">
                                                <td>{{ item.detail.medicamento }}</td>
                                                <td>{{ item.detail.administracion }}</td>
                                                <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}}</td>
                                                <td>{{ item.detail.observaciones }}</td>
                                            </template>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div v-else>
                                        <div class="text-center text-danger">No tiene datos en receta</div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.metadata.tratamiento_aplicacion" class="row">
                                <div class="col-12">
                                    <div class="timeDate">Tratamiento</div>
                                    <div class="timeDescrip">
                                        <table class="table align-middle m-0">
                                        <thead class="small flex-sm-nowrap font-weight-bold">
                                        <tr class="font-weight-bold small">
                                            <th class="font-weight-bold">Medicamento</th>
                                            <th class="font-weight-bold">Cantidad</th>
                                        </tr>
                                        </thead>
                                        <tbody class="small">
                                        <tr v-for="item in item.metadata.tratamiento_aplicacion" :key="item.id">
                                            <template v-if="(typeof item.detail !== 'undefined')">
                                                <td>{{ item.detail.medicamento }}</td>
                                                <td>{{ item.detail.cantidad }}</td>
                                            </template>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row">
                                <div class="col-12">
                                    <div class="timeDate"><i class="fas fa-info-circle text-primary"></i> Infomación</div>
                                    <div class="timeDescrip">
                                        Esta orden no tene datos disponibles
                                    </div>
                                </div>
                            </div>
                        </template>
                    </li>
                </ul>
            </section>
            <section v-else class="mt-3 d-flex justify-content-center">
                <p class="text-muted">No se encontro historico de tratamientos</p>
            </section>
        </box>
        <box title="Receta" icon="fas fa-receipt" :contraction='true'>
            <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined'">
                <div class="text-center text-danger">No tiene datos en receta</div>
            </div>
            <div v-else>
                <table class="table align-middle m-0">
                    <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Medicamento</th>
                        <th class="font-weight-bold">Administración</th>
                        <th class="font-weight-bold">Dosis y frecuencia</th>
                        <th class="font-weight-bold">Observaciones</th>
                        <th class="font-weight-bold"></th>
                    </tr>
                    </thead>
                    <tbody class="small">
                    <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                        <template v-if="(typeof item.detail !== 'undefined')">
                            <td>{{ item.detail.medicamento }}</td>
                            <td>{{ item.detail.administracion }}</td>
                            <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}}</td>
                            <td>{{ item.detail.observaciones }}</td>
                            <td>
                                <i @click="editReceta(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                                <i @click="deleteReceta(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="mt-5" v-if="showEditionReceta">
                <div>
                    <h5>Agregar a receta</h5>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div>
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="recetaMedicamentoEnInventario" :checked="recetaMedicamentoEnInventario"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                Medicamento en inventario
                            </span>
                            </label>
                        </div>
                        <div class="form-group" v-if="recetaMedicamentoEnInventario">
                            <label>Medicamento</label>
                            <multiselect v-model="receta.detail.medicamento"
                                        :options="recetaSearchMedicamentos"
                                        track-by="id"
                                        label="nombre"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Búsqueda de productos"
                                        :multiple="false"
                                        :internal-search="false"
                                        @search-change="getData">
                            </multiselect>
                        </div>
                        <div class="form-group" v-else>
                            <label>Medicamento</label>
                            <input type="text" class="form-control" v-model="receta.detail.medicamento"/>
                        </div>
                    </div>

                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label>Administración</label>
                            <!--<input type="text" class="form-control" v-model="receta.detail.fuente"/>-->
                            <select v-model="receta.detail.administracion" class="form-control">
                                <option :key="item.name" v-for="item in administracionOptions" :value="item.name">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label>Dosis</label>
                            <input class="form-control" v-model="receta.detail.dosis" type="text">
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label>Cada</label>
                            <input type="text" class="form-control" v-model="receta.detail.cada"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label>Durante</label>
                            <input type="text" class="form-control" v-model="receta.detail.durante"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="form-group">
                            <label>Observaciones</label>
                            <input type="text" class="form-control" v-model="receta.detail.observaciones"/>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-5">
                    <button @click="cancelEditReceta" class="btn btn-danger mr-3">Cancelar</button>
                    <button @click="saveReceta" class="btn btn-primary mr-3">Guardar</button>
                </div>
            </div>
            <div v-else class="mt-4 text-right">
                <button class="btn btn-primary" @click="addReceta">
                    <i class="fa fa-plus mr-2"></i>Agregar a receta
                </button>
            </div>
        </box>
        <box title="Aplicar medicamentos" icon="fas fa-receipt" :contraction='true'>
            <div class="mt-5 mb-2" v-if="showEditionAplicacion">
                <div>
                    <h5>Aplicar medicamentos / procedimientos / vacunas o servicios</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="aplicacionMedicamentoEnInventario" :checked="aplicacionMedicamentoEnInventario"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                Medicamento en inventario
                            </span>
                            </label>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group" v-if="aplicacionMedicamentoEnInventario">
                            <label>Medicamento</label>
                            <multiselect v-model="aplicacion.detail.medicamento"
                                        :options="recetaSearchMedicamentos"
                                        track-by="id"
                                        label="nombre"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :preserve-search="true"
                                        placeholder="Búsqueda de productos"
                                        :multiple="false"
                                        :internal-search="true"
                                        @search-change="getData">
                                <template slot="noOptions">
                                    <div class="option__desc">
                                        <span class="option__title">Inicie su búsqueda</span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                        <div class="form-group" v-else>
                            <label>Medicamento</label>
                            <input type="text" class="form-control" v-model="aplicacion.detail.medicamento"/>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Cantidad</label>
                            <input class="form-control" v-model="aplicacion.detail.cantidad" type="number">
                        </div>
                    </div>
                </div>
                <div class="text-right mt-5">
                    <button @click="cancelEditAplicacion" class="btn btn-danger mr-3">Cancelar</button>
                    <button @click="saveAplicacion" class="btn btn-primary mr-3">Aplicar</button>
                </div>
            </div>
            <div v-else class="mt-4 mb-2 text-right">
                <button class="btn btn-primary" @click="addMedicamento">
                    <i class="fa fa-plus mr-2"></i>Aplicar a medicamentos / procedimientos / vacunas o servicios
                </button>
            </div>
            <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_aplicacion === 'undefined'">
                <div class="text-center text-danger">No tiene medicamentos aplicados</div>
            </div>
            <div v-else>
                <table class="table align-middle m-0">
                    <thead class="small flex-sm-nowrap font-weight-bold">
                    <tr class="font-weight-bold small">
                        <th class="font-weight-bold">Medicamento</th>
                        <th class="font-weight-bold">Cantidad</th>
                        <th class="font-weight-bold"></th>
                    </tr>
                    </thead>
                    <tbody class="small">
                    <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_aplicacion" :key="item.id">
                        <template v-if="(typeof item.detail !== 'undefined')">
                            <td>{{ item.detail.medicamento }}</td>
                            <td>{{ item.detail.cantidad }}</td>
                            <td>
                                <i @click="deleteAplicacion(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>
        </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {mapGetters,mapActions} from "vuex";

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';
import OrderProductSearch from "/src/modules/order/components/OrderProductSearch";

// Componentes

export default {
    components: {
        OrderProductSearch,
        Box,
        Multiselect,
        DatePicker,
    },
    props: [
        'type',
    ],
    data() {
        return {
            recetaSearchMedicamentos: [{
                nombre:'Ingrese producto o servicio para buscar...'
            }],

            recetaMedicamentoEnInventario: false,
            aplicacionMedicamentoEnInventario: false,

            recomendaciones: '',
            recetaMedicamento: '',
            recetaAdministracion: '',
            recetaDosis: '',
            recetaCada: '',
            cadaOptions: [
                {
                    "name": "Diario",
                    "id": 0
                },
                {
                    "name": "Antes de cada comida",
                    "id": 1
                },
                {
                    "name": "Después de cada comida",
                    "id": 2
                },
                {
                    "name": "q1h",
                    "id": 3
                },
                {
                    "name": "q2h",
                    "id": 4
                },
                {
                    "name": "q3h",
                    "id": 5
                },
                {
                    "name": "q4h",
                    "id": 6
                },
                {
                    "name": "q6h",
                    "id": 7
                },
                {
                    "name": "q8h",
                    "id": 8
                },
                {
                    "name": "q12h",
                    "id": 9
                },
                {
                    "name": "HS",
                    "id": 10
                }
            ],


            recetaDurante: '',
            recetaDuranteTiempo: '',
            duranteOptions: [
                {
                    'name': 'minuto(s)',
                    'id': 0,
                },
                {
                    'name': 'hora(s)',
                    'id': 1,
                },
                {
                    'name': 'dia(s)',
                    'id': 2,
                },
                {
                    'name': 'semana(s)',
                    'id': 3,
                },
                {
                    'name': 'mes(es)',
                    'id': 4,
                },
                {
                    'name': 'año(s)',
                    'id': 5,
                }
            ],

            rowDataReceta: [],

            administracionOptions: [
                {
                    'name': 'Vía oral',
                },
                {
                    'name': 'Subcutánea',
                },
                {
                    'name': 'Intramuscular',
                },
                {
                    'name': 'Intravenosa',
                },
                {
                    'name': 'Intratecal',
                },
                {
                    'name': 'Vía sublingual',
                },
                {
                    'name': 'Vía rectal',
                },
                {
                    'name': 'Vía vaginal',
                },
                {
                    'name': 'Vía ocular',
                },
                {
                    'name': 'Vía ótica',
                },
                {
                    'name': 'Vía nasal',
                },
                {
                    'name': 'Vía inhalatoria',
                },

                {
                    'name': 'Vía cutánea',
                },
                {
                    'name': 'Vía transdérmica',
                },
                {
                    'name': 'Vía tópica',
                },
                {
                    'name': 'Nebulizaciones',
                },
            ],

            // procedimientos
            data_clicked_id: '',

            // medicamentos
            showEditionReceta: false,
            showProductSearchBox: false,
            receta: {   
                id: 0,
                detail: {
                    medicamento: '',
                    nombreYFormulacion: '',
                    fechaInicio: dayjs().format('DD-MM-YYYY HH:mm'),
                    fuente: '',
                    observaciones: '',
                }
            },
            aplicacion: {
                id: 0,
                detail: {
                    cantidad: 1,
                    medicamento: '',
                    medicamentoId: 0,
                }
            },

            // Procedimientos
            showEditionProcedimiento: false,
            procedimiento: {},

            // Medicamentos o productos
            showEditionAplicacion: false,
            producto: {},

            // otros
            observaciones: '',
            timeline: true,
            tratamientos: {
                content: {}
            }
        };
    },
    watch:{
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
                this.observaciones = (this.GetMedicoFichaActive.metadata.tratamiento) ? this.GetMedicoFichaActive.metadata.tratamiento.observaciones : '';
            }
        },
        recetaMedicamentoEnInventario: function(newValue, oldValue) {
            if(!this.recetaMedicamentoEnInventario && (typeof this.receta.detail.medicamento === 'object') ){
                this.receta.detail.medicamento='';
            }
    },
        aplicacionMedicamentoEnInventario: function(newValue, oldValue) {
            if(!this.aplicacionMedicamentoEnInventario && (typeof this.aplicacion.detail.medicamento === 'object') ){
                this.aplicacion.detail.medicamento='';
            }
    }
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
            GetHistoryFiltred: 'GetHistoryFiltred',
        })
    },
    mounted() {
        this.emitter.emit("medico-ficha-load",['tratamiento_aplicacion','tratamiento_receta','tratamiento'])

        this.FetchHistoryFiltred(['tratamiento_aplicacion','tratamiento_receta','tratamiento']);

    },
    methods: {
        ...mapActions(
            [
            // Recibe un argumento (slugs) los slugs de la metadata a consultar
            'FetchHistoryFiltred',
        ]),
        dayjs,
        Numbro,
        stripTags: function (text) {
            return Tools.stripTags(text);
        },
        getData(query) {
            const self = this;
            self.isLoading = true;
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/products/search/' + query, {},
                    function (res) {
                        self.recetaSearchMedicamentos = res.data;
                        self.isLoading = false;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.isLoading = false;
                    });
            }
        },
        // Procedimientos
        part_clicked(val) {
            this.data_clicked_id = val.target.getAttribute('id')
           
        },
        // Medicamentos
        // Recetas
        resetMedicamento() {
            this.producto = {
                id: 0,
                detail: {
                    productoId: '',
                    nombre: '',
                    cantidad: '',
                    observaciones: '',
                }
            };
        },
        cancelEditAplicacion() {
            this.resetMedicamento();
            this.showEditionAplicacion = false;
        },
        saveMedicamento() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.procedimiento.id,
                    slug: 'tratamiento_medicamentos',
                    detail: self.procedimiento.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh",['tratamiento_medicamentos']); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditAplicacion();
        },
        addMedicamento() {
            this.resetMedicamento();
            this.showEditionAplicacion = true;
        },
        editMedicamento(item) {
            this.resetMedicamento();
            this.producto = item;
            this.showEditionAplicacion = true;
        },
        deleteMedicamento(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh",['tratamiento_medicamentos']);
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },

        buscarMedicamento() {
            this.showProductSearchBox = true;
        },
        productSelected: function (data) {

           

            /*this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');*/

            const precio = Numbro((data.onSale) ? data.precioOnSale : data.precio).formatCurrency();

            this.producto = {
                id: data.id,
                detail: {
                    productoId: data.id,
                    nombre: data.nombre,
                    cantidad: 1,
                    observaciones: '',
                    precio: precio,
                },
            };
            this.showProductSearchBox = false;
            this.showClientSearch = true;
        },

        // Recetas
        resetReceta() {
            this.receta = {
                id: 0,
                detail: {
                    nombreYFormulacion: '',
                    fechaInicio: dayjs().format('DD-MM-YYYY HH:mm'),
                    fuente: '',
                    observaciones: '',
                }
            };
        },
        cancelEditReceta() {
            this.showEditionReceta = false;
            this.resetMedicamento();
        },
        saveReceta() {
            const self = this;
            let newReceta;
            if (typeof self.receta.detail.medicamento === 'object') {
                // Extraer el resto de las propiedades de detail
                const { medicamento, ...restoDetail } = self.receta.detail;
                // Obtener solo el nombre del medicamento si existe
                newReceta = {
                    medicamento: medicamento.nombre,
                    ...restoDetail
                };
            } else {
                newReceta = self.receta.detail;
            }
            if (newReceta.medicamento && newReceta.medicamento.length > 0 && newReceta.medicamento !== 'Ingrese producto o servicio para buscar...') {
                API.send('POST', 'orders/metadata/save/', {
                        orderId: self.GetMedicoFichaActive.orden.id,
                        metadataId: self.receta.id,
                        slug: 'tratamiento_receta',
                        detail: newReceta,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh", ['tratamiento_receta']);
                        self.FetchHistoryFiltred(['tratamiento_aplicacion','tratamiento_receta','tratamiento']); // Re-fetch history
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            } else {
                API.showErrorAlert('No se ha ingresado un medicamento para guardar');
            }

            this.cancelEditReceta();
        },
        saveAplicacion() {
            const self = this;
            let newMedicamento;
            if (typeof self.aplicacion.detail.medicamento === 'object') {
                // Extraer el resto de las propiedades de detail
                const { medicamento, cantidad, ...restoDetail } = self.aplicacion.detail;
                newMedicamento = {
                    medicamento: medicamento.nombre,
                    medicamentoId: medicamento.id,
                    precio: medicamento.precio,
                    cantidad: cantidad
                };
            } else {
                newMedicamento = self.aplicacion.detail;
            }

            if (newMedicamento.medicamento && newMedicamento.medicamento.length > 0 && newMedicamento.medicamento !== 'Ingrese producto o servicio para buscar...') {
                API.send('POST', 'orders/metadata/save/', {
                        orderId: self.GetMedicoFichaActive.orden.id,
                        metadataId: self.aplicacion.id,
                        slug: 'tratamiento_aplicacion',
                        detail: newMedicamento
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh", ['tratamiento_aplicacion']);
                        self.FetchHistoryFiltred(['tratamiento_aplicacion','tratamiento_receta','tratamiento']); // Re-fetch history
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            } else {
                API.showErrorAlert('No se ha ingresado un medicamento para guardar');
            }

            this.cancelEditAplicacion();
        },
        deleteAplicacion(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh",['tratamiento_aplicacion']);
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        addReceta() {
            this.resetReceta();
            this.showEditionReceta = true;
        },
        editReceta(item) {
            this.resetReceta();
            this.receta.id = item.id;
            this.receta.detail.medicamento = item.detail.medicamento;
            this.receta.detail.nombreYFormulacion = item.detail.nombreYFormulacion;
            this.receta.detail.fechaInicio = item.detail.fechaInicio;
            this.receta.detail.fuente = item.detail.fuente;
            this.receta.detail.observaciones = item.detail.observaciones;
            this.receta.detail.administracion = item.detail.administracion;
            this.receta.detail.dosis = item.detail.dosis;
            this.receta.detail.cada = item.detail.cada;
            this.receta.detail.durante = item.detail.durante;
            this.showEditionReceta = true;
        },
        deleteReceta(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh",['tratamiento_receta']);
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
 
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            arrToSave['observaciones'] = this.observaciones;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'tratamiento',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-notify",['tratamiento']);
                    self.FetchHistoryFiltred(['tratamiento_aplicacion','tratamiento_receta','tratamiento']); // Re-fetch history
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        changeTimeline() {
            this.timeline = !this.timeline;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
    }
};

</script>

<style scoped>
.timeline-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.tl,
.tl::before,
.tl::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tl {
  display: grid;
  align-content: center;
  gap: 2rem;
}

.tl h2 {
  text-align: center;
}

.tl ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: 100%;
  margin-inline: auto;
}

.tl ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

.tl ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

.tl ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

.tl ul li .timeheader {
  --dateH: 1.5rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);
  text-align: center;
  background-color: var(--accent-color);
  color: white;
  display: grid;
  place-content: center;
  position: relative;
  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

.tl ul li .timeheader::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

.tl ul li .timeheader::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

.tl ul li .timeDate,
.tl ul li .timeDescrip {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
  word-wrap: break-word;
}

.tl ul li .timeDate {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
}

.tl ul li .timeDescrip {
  padding-block-end: 1.5rem;
}

.tl ul li .timeDate::before,
.tl ul li .timeDescrip::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.1); /* Reduced shadow intensity */
  left: 50%;
  border-radius: 50%;
  filter: blur(2px); /* Reduced blur */
  transform: translate(-50%, 50%);
}

.tl ul li .timeDate::before {
  bottom: calc(100% + 0.125rem);
}

.tl ul li .timeDescrip::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 2040px) {
  .tl ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .tl ul::before {
    grid-column: 2;
  }
  .tl ul li:nth-child(odd) {
    grid-column: 1;
  }
  .tl ul li:nth-child(even) {
    grid-column: 3;
  }
  .tl ul li:nth-child(2) {
    grid-row: 2/4;
  }
  .tl ul li:nth-child(odd) .timeheader::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }
  .tl ul li:nth-child(odd) .timeheader::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .tl ul li:nth-child(odd) .timeheader {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.changetimeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px 2px 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  height: 40px;
  padding: 0 10px;
}

.changetimeline:hover {
  background-color: rgba(145, 144, 157, 0.097);
}

.timeline-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.changetimeline p {
  margin: 0;
}
</style>