<template>
    <div class="colas-screen">
        <div class="colas-screen-header">
            <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class="logo"/>
            <button v-if="!audioEnabled" class="btn btn-primary float-right" @click="enableAudio"><i class="fas fa-file-audio mr-2"></i>Habilitar audio</button>
        </div>
        <div class="row m-0">
            <div class="col-12 col-sm-7">
                <div class="row m-0">
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b1.n}}</div>
                            <div class="screen-text">{{boxes.b1.t}}</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b2.n}}</div>
                            <div class="screen-text">{{boxes.b2.t}}</div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b3.n}}</div>
                            <div class="screen-text">{{boxes.b3.t}}</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b4.n}}</div>
                            <div class="screen-text">{{boxes.b4.t}}</div>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b5.n}}</div>
                            <div class="screen-text">{{boxes.b5.t}}</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="screen-box">
                            <div class="screen-title">{{boxes.b6.n}}</div>
                            <div class="screen-text">{{boxes.b6.t}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-5 pl-0">
                <div class="row m-0">
                    <div class="col-12 pl-0 pr-2">
                        <div class="screen-box-big">
                            <div class="screen-title">{{boxes.now.n}}</div>
                            <div class="screen-text">{{boxes.now.t}}</div>
                        </div>
                    </div>
                    <div class="col-12 pl-0 pr-2">
                        <div class="screen-video-container">
                            <video class="screen-video" loop autoplay="autoplay" controls muted>
                                <source src="https://abkstorage-public.s3.amazonaws.com/_shared/Intro-academia-virtual.mov" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import "/src/layout/admin/assets/css/colas-screen.css";
import {mapGetters} from "vuex";
import API from "src/core/Api";

export default {
    components: {},
    computed: {
        ...mapGetters({
            AuthGetToken: 'AuthGetToken',
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    data() {
        return {
            ws: false,
            audioEnabled: false,
            audioDefault: false,

            // boxes
            boxes: {
                now: {
                    n: "",
                    t: "",
                    v: ""
                },
                b1: {
                    n: "",
                    t: "",
                    v: ""
                },
                b2: {
                    n: "",
                    t: "",
                    v: ""
                },
                b3: {
                    n: "",
                    t: "",
                    v: ""
                },
                b4: {
                    n: "",
                    t: "",
                    v: ""
                },
                b5: {
                    n: "",
                    t: "",
                    v: ""
                },
                b6: {
                    n: "",
                    t: "",
                    v: ""
                }
            }
        };
    },
    mounted() {
        const self = this;
        const localidad = 1;

        // habilita audio
        this.audioDefault = new Audio();
        this.audioDefault.load();

        //const channel = this.AuthGetUserInfo.id + "_" + localidad + '_colas_screen';
        const channel = 'channel_test';
        this.ws = new API.websocket(channel, this.AuthGetToken, function (data) {
            if (typeof data.message_data !== 'undefined') {
                self.boxes = data.message_data;
                console.log(self.boxes)

                if (typeof self.boxes.now.v !== 'undefined' && self.boxes.now.v && self.boxes.now.v !== '') {
                    self.textToSpeech(self.boxes.now.v);
                }
            }
        }, function (error) {
            console.log(error)
        });
    },
    methods: {
        enableAudio() {
            this.audioEnabled = true;
            this.audioDefault.src = 'https://abkstorage-public.s3.amazonaws.com/_ISI_SOFTWARE/rep_auto_enabled.mp3'
            this.audioDefault.play();
        },
        textToSpeech(text) {
            const self = this;

            const base64toBlob = function (base64Data, contentType) {
                contentType = contentType || '';
                var sliceSize = 1024;
                var byteCharacters = atob(base64Data);
                var bytesLength = byteCharacters.length;
                var slicesCount = Math.ceil(bytesLength / sliceSize);
                var byteArrays = new Array(slicesCount);

                for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                    var begin = sliceIndex * sliceSize;
                    var end = Math.min(begin + sliceSize, bytesLength);

                    var bytes = new Array(end - begin);
                    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0);
                    }
                    byteArrays[sliceIndex] = new Uint8Array(bytes);
                }
                return new Blob(byteArrays, { type: contentType });
            }

            API.send('POST', 'colas/get-speech', {
                    text: text
                }, function (data) {

                    const blob = base64toBlob(data.data, 'audio/mp3');
                    const blobUrl = URL.createObjectURL(blob);
                    console.log(blobUrl)
                    self.audioDefault.src = blobUrl
                    self.audioDefault.play();

                    /*console.log(dataBin)
                    const audioContext = new AudioContext();
                    const arr = Uint8Array.from(dataBin)
                    const audio = await audioContext.decodeAudioData(arr.buffer);
                    const source = audioContext.createBufferSource();
                    source.buffer = audio;
                    source.connect(audioContext.destination);
                    source.start(0);*/
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
    }
};
</script>
